import { usePermissions } from 'features/permissions/usePermissions';
import { useFeatureFlag } from 'features/feature-set/useFeatureFlag';
import { PermissionTypes } from 'features/permissions/types';

export const useSIP = () => {
  const { hasPermissions } = usePermissions();
  const isFeatureEnabled = useFeatureFlag('telephony');

  const canManagePhoneUsers = hasPermissions(PermissionTypes.managePhoneUsers);
  const isFeatureAvailable = isFeatureEnabled && canManagePhoneUsers;

  return {
    isFeatureAvailable,
    canManagePhoneUsers,
  };
};
