import { useTranslation } from 'react-i18next';
import ToolbarMenuItem from 'features/toolbar/ToolbarMenuItem/ToolbarMenuItem';
import { ToolbarMenuItemWrapperProps } from 'features/toolbar/types';
import { useSharedNotesControl } from 'features/toolbar/Toolbar/shared-notes/useSharedNotesControl';

const SharedNotesControl = ({ onClick }: ToolbarMenuItemWrapperProps) => {
  const { t } = useTranslation('room');

  const { active, icon, togglePanel, isFeatureAvailable } = useSharedNotesControl();

  if (!isFeatureAvailable) {
    return null;
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onClick?.(event);
    togglePanel();
  };

  return (
    <ToolbarMenuItem
      id="shared-notes-control"
      overlay={t('toolbar.shared_notes')}
      ariaLabel={t('toolbar.shared_notes')}
      icon={icon}
      active={active}
      onClick={handleClick}
    />
  );
};

export default SharedNotesControl;
