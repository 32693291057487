import ToolbarMenuItem from 'features/toolbar/ToolbarMenuItem/ToolbarMenuItem';
import React from 'react';
import { ToolbarMenuItemWrapperProps } from 'features/toolbar/types';
import { useSIPControl } from 'features/toolbar/Toolbar/controls/sip/useSIPControl';

const SIPControl = ({ onClick }: ToolbarMenuItemWrapperProps) => {
  const { icon, isFeatureAvailable, toggleConnectionModal, label } = useSIPControl();

  if (!isFeatureAvailable) {
    return null;
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onClick?.(event);
    toggleConnectionModal();
  };

  return (
    <ToolbarMenuItem id="sip" overlay={label} ariaLabel={label} icon={icon} onClick={handleClick} />
  );
};

export default SIPControl;
