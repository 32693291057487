import { styled } from '@mui/material/styles';
import { AvatarSize } from 'features/layout/features/dimensions/types';
import { SourceDetails } from 'features/layout/types';
import FeedDebugControls from 'features/dev-mode/components/FeedDebugControls';
import RemoteScreenshare from 'features/streaming/components/media/RemoteScreenshare';
import { TileControlButtons } from 'features/streaming/components/TileControlButtons';
import LocalScreenshare from 'features/streaming/components/media/LocalScreenshare';
import RemoteMedia from 'features/streaming/components/media/RemoteMedia';
import LocalMedia from 'features/streaming/components/media/LocalMedia';
import DebugMedia from 'features/streaming/components/media/DebugMedia';
import React from 'react';
import { useAppSelector } from 'store/hooks';
import { selectUserById } from 'features/users/usersSlice';
import { HandRaiseBadge } from 'features/streaming/components/HandRaiseBadge';
import { useRecorder } from 'features/recorder/useRecorder';
import { hoverParentStyles } from 'features/layout/useHoverParent';

const Root = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  justifyContent: 'center',
  padding: theme.spacing(4),
  '&:focus': {
    outline: 'none',
  },
  ...hoverParentStyles,
}));

const mediaByKind = {
  debug: DebugMedia,
  local: LocalMedia,
  'screenshare-local': LocalScreenshare,
  remote: RemoteMedia,
  'screenshare-remote': RemoteScreenshare,
  custom: () => null,
};

interface StreamDisplayProps {
  source: SourceDetails;
  avatarSize?: AvatarSize;
  maximized?: boolean;
}

const StreamDisplay = React.memo(
  ({ source, avatarSize, maximized, ...mediaProps }: StreamDisplayProps) => {
    const isRecorder = useRecorder();

    const user = useAppSelector((state) => selectUserById(state, source.userId));
    if (!user) {
      return null;
    }

    const isUserTile = source.kind === 'local' || source.kind === 'remote';
    const handRaised = isUserTile ? user.handRaised : undefined;

    const Media = mediaByKind[source.kind];

    return (
      <Root tabIndex={0}>
        <Media source={source} avatarSize={avatarSize} {...mediaProps} />
        {!isRecorder && (
          <>
            {handRaised && <HandRaiseBadge />}
            <TileControlButtons source={source} collapseButtons={!maximized} />
          </>
        )}
        <FeedDebugControls source={source} />
      </Root>
    );
  }
);

export default StreamDisplay;
