import React from 'react';
import { useFeatureFlag } from 'features/feature-set/useFeatureFlag';
import { pinControlToggled } from 'features/layout/actions';
import { selectCurrentLayoutMode } from 'features/layout/features/config/configSlice';
import { selectMaximizedStream } from 'features/layout/features/content/contentSlice';
import { selectExposedMode } from 'features/layout/features/contentDimensions/contentDimensionsSlice';
import { LayoutMode, SourceDetails } from 'features/layout/types';
import { sourceMatches } from 'features/streaming/utils';
import { CustomTileData } from 'features/layout/features/config/types';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { formatCustomTileToSource } from 'utils/custom-tile/format';

export const useCustomTilePinControl = (
  data: CustomTileData,
  clickCallback: (e: React.MouseEvent<HTMLButtonElement | HTMLLIElement, MouseEvent>) => void
) => {
  const source: SourceDetails = formatCustomTileToSource(data);

  const dispatch = useAppDispatch();
  const { t } = useTranslation('room');

  const enabled = useFeatureFlag('pin');
  const layoutMode = useAppSelector(selectCurrentLayoutMode);

  const maximizedFeed = useAppSelector(selectMaximizedStream);
  const exposedMode = useAppSelector(selectExposedMode);

  const controlActive = sourceMatches(maximizedFeed, source) && exposedMode === 'pin';

  const getText = () => {
    if (!data) {
      return '';
    }

    return controlActive ? t('tooltips.unpin') : t('tooltips.pin');
  };

  // eslint-disable-next-line no-nested-ternary
  const onClick = (e: React.MouseEvent<HTMLLIElement | HTMLButtonElement, MouseEvent>) => {
    clickCallback?.(e);
    dispatch(pinControlToggled({ controlActive, source }));
  };

  const showControl = () => {
    if (!enabled) {
      return false;
    }

    return layoutMode !== LayoutMode.auto;
  };

  return {
    show: showControl(),
    icon: controlActive ? 'unpin' : 'pin',
    onClick,
    text: getText(),
  };
};
