import { call, put } from 'redux-saga/effects';
import { Action } from '@reduxjs/toolkit';
import { signalingQuestionLiveAnswerCancelled } from 'features/qa/actions';
import { closeActionNotification } from 'features/notifications/thunks/closeActionNotification';
import { hasPermissionsSaga } from 'features/permissions/sagas/hasPermissionsSaga';
import { PermissionTypes } from 'features/permissions/types';
import { liveAnswerCancelled } from 'features/qa/qaSlice';

export function* onLiveAnswerCancelledSaga(
  action: ReturnType<typeof signalingQuestionLiveAnswerCancelled>
) {
  const cleanupNotificationThunk: Action = yield call(
    closeActionNotification,
    `${action.payload.question.id}_${action.payload.id}`
  );
  yield put(cleanupNotificationThunk);

  const canSeeAllQuestions: boolean = yield call(
    hasPermissionsSaga,
    PermissionTypes.seeAllQuestions
  );
  const canModerateQA: boolean = yield call(hasPermissionsSaga, PermissionTypes.moderateQA);

  yield put(
    liveAnswerCancelled(action.payload, {
      cannotSeeQuestions: !canModerateQA && !canSeeAllQuestions,
    })
  );
}
