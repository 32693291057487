import { useAppDispatch } from 'store/hooks';
import { openModal } from 'features/modal/modalSlice';
import { useSIP } from 'features/sip/useSIP';
import { useTranslation } from 'react-i18next';

export const useSIPControl = () => {
  const { t } = useTranslation('room');
  const dispatch = useAppDispatch();

  const { isFeatureAvailable } = useSIP();

  const toggleModal = () => {
    dispatch(openModal('phoneConnection'));
  };

  const label = t('toolbar.sip');

  return {
    toggleConnectionModal: toggleModal,
    icon: 'phone',
    isFeatureAvailable,
    label,
  };
};
