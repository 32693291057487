import React from 'react';
import Tile from 'features/layout/tiles/Tile';
import { CustomTileData } from 'features/layout/features/config/types';
import CustomTileDisplay from 'features/layout/tiles/CustomTileDisplay';

type CustomTileProps = {
  data: CustomTileData;
};

const CustomTile = ({ data }: CustomTileProps) => (
  <Tile>
    <CustomTileDisplay data={data} />
  </Tile>
);

export default CustomTile;
