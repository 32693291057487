import { alpha, Components } from '@mui/material/styles';
import { Theme } from '@mui/material';

export const overrideComponents = (themeCore: Theme): Components => ({
  MuiContainer: {
    styleOverrides: {
      root: {
        padding: themeCore.spacing(0, 6),
        [themeCore.breakpoints.up('sm')]: {
          padding: themeCore.spacing(0, 6),
        },
      },
    },
    defaultProps: {
      maxWidth: 'xl',
    },
  },
  MuiTab: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        '&.Mui-focusVisible': {
          color: themeCore.palette.primary.light,
        },
      },
    },
  },
  MuiTextField: {
    defaultProps: {
      variant: 'standard',
      size: 'small',
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        color: themeCore.palette.text.primary,
        '&.Mui-focusVisible': {
          backgroundColor: themeCore.palette.action.hover,
        },
        '&:hover': {
          backgroundColor: themeCore.palette.action.hover,
        },
      },
      sizeSmall: {
        padding: '2px',
      },
      sizeMedium: {
        padding: '4px',
      },
    },
  },
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        '&.Mui-focusVisible': {
          boxShadow: `0 0 0 3px ${alpha(themeCore.palette.primary.main, 0.35)}`,
        },
        '&.MuiButton-text.MuiButton-sizeMedium': {
          padding: '8px 8px',
        },
        '&.MuiButton-outlined.MuiButton-sizeMedium': {
          padding: '7px 16px',
        },
      },
      sizeMedium: {
        padding: '8px 16px',
      },
      startIcon: {
        marginTop: '-5px',
        marginBottom: '-5px',
        '.MuiButton-outlinedSizeMedium &': {
          marginLeft: '-12px',
        },
        '&>.Icon-root': {
          fontSize: 30,
        },
      },
      endIcon: {
        marginTop: '-5px',
        marginBottom: '-5px',
        '.MuiButton-outlinedSizeMedium &': {
          marginRight: '-12px',
        },
        '&>.Icon-root': {
          fontSize: 30,
        },
      },
    },
    defaultProps: {
      disableRipple: true,
      disableElevation: true,
    },
  },
  MuiDialog: {
    styleOverrides: {
      paperWidthMd: {
        maxWidth: 700,
      },
      paperWidthXs: {
        maxWidth: 440,
      },
    },
    defaultProps: {
      maxWidth: 'sm',
      fullWidth: true,
      scroll: 'body',
    },
  },
  MuiDialogContentText: {
    styleOverrides: {
      root: {
        color: themeCore.palette.text.primary,
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        paddingBottom: themeCore.spacing(6),
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        '& .MuiButton-root': {
          letterSpacing: '0.02857em',
          textTransform: 'uppercase',
        },
        '& .MuiButton-sizeMedium': {
          fontSize: '0.875rem',
        },
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        padding: '9px 12px',
      },
    },
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiListItemIcon: {
    styleOverrides: {
      root: {
        fontSize: 32,
        margin: '-5px 0',
        color: 'inherit',
      },
    },
  },
  MuiFormControl: {
    defaultProps: {
      variant: 'standard',
    },
  },
  MuiUseMediaQuery: {
    defaultProps: {
      noSsr: true,
    },
  },
  MuiToggleButton: {
    defaultProps: {
      disableRipple: true,
    },
  },
});
