import { AnswerPayload, QAState, Question } from 'features/qa/types';

export const upsertQuestionFromAnswer = (state: QAState, answer: AnswerPayload) => {
  const question = state.questions.entities[answer.question.id];
  if (question) {
    return question;
  }

  // normalize and add a new question to the store
  const normalizedQuestion: Question = {
    ...answer.question,
    answers: [],
  };

  state.questions.entities[normalizedQuestion.id] = normalizedQuestion;
  state.questions.allIds.push(normalizedQuestion.id);

  return normalizedQuestion;
};
