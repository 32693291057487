import { QAState } from 'features/qa/types';

export const updateQuestionsState = (
  state: QAState,
  questions: { entities: any; allIds: string[] },
  answers: any,
  totalCount: number
) => {
  if (!state.panelOpen) {
    const currentTotal = state.questions.allIds.length + Object.keys(state.answers.entities).length;
    state.unreadCount = Math.max(0, totalCount - currentTotal);
  }

  state.questions.entities = questions.entities;
  state.questions.allIds = questions.allIds;
  state.answers.entities = answers;
};
