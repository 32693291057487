import { muteAudioSaga } from 'features/streaming/sagas/muteAudioSaga';
import { call } from 'redux-saga/effects';
import { AudioPool } from 'utils/webrtc/AudioPool';

export function* toggleGlobalAudioMuteSaga() {
  if (AudioPool.globalMute) {
    yield call(AudioPool.unmuteAll);
  } else {
    yield call(AudioPool.muteAll);
    yield call(muteAudioSaga);
  }
}
