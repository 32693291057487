import ToolbarMenuItem from 'features/toolbar/ToolbarMenuItem/ToolbarMenuItem';
import { useGlobalAudioMuteControl } from './useGlobalAudioMuteControl';

const GlobalAudioMuteControl = () => {
  const { toggle, icon, label, color } = useGlobalAudioMuteControl();

  return (
    <ToolbarMenuItem
      id="toolbar-global-mute"
      overlay={label}
      overlayType="tooltip"
      icon={icon}
      controlColor={color}
      ariaLabel={label}
      onClick={toggle}
    />
  );
};

export default GlobalAudioMuteControl;
