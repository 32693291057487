import { call } from 'redux-saga/effects';
import { SignalingSocket } from 'services/signaling';
import { dismissQuestionRequested } from 'features/qa/actions';

export function* dismissQuestionSaga(action: ReturnType<typeof dismissQuestionRequested>) {
  yield call(SignalingSocket.send, {
    event: 'dismissQuestion',
    data: {
      id: action.payload.id,
    },
  });
}
