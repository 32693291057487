import { SignalingRoomUser } from 'services/signaling';

export const filterSubscriptionsWithVideo: (
  subscriptions?: SignalingRoomUser[]
) => SignalingRoomUser[] = (subscriptions = []) =>
  subscriptions.filter((sub) => {
    if (sub.streams) {
      return sub.streams.some((stream) => stream.type === 'video');
    }

    return false;
  });

export const orderSubscriptions: (subscriptions?: SignalingRoomUser[]) => SignalingRoomUser[] = (
  subscriptions = []
) =>
  subscriptions.sort(
    (subscriptionA, subscriptionB) =>
      (subscriptionB.startedTalkingTimestamp || subscriptionB.joinTimestamp) -
      (subscriptionA.startedTalkingTimestamp || subscriptionA.joinTimestamp)
  );

export const calculateVideoFeeds: (subscriptions?: SignalingRoomUser[]) => number = (
  subscriptions = []
) => {
  let feedsWithVideo = 0;
  for (const user of subscriptions) {
    if (user.streams) {
      for (const stream of user.streams) {
        if (stream.type === 'video') {
          feedsWithVideo += 1;
        }
      }
    }
  }
  return feedsWithVideo;
};
