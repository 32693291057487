import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initVirtualBackground } from 'features/virtual-backgrounds/thunks/initVirtualBackground';
import {
  CustomVirtualBackground,
  VirtualBackgroundsState,
  VirtualBackgroundSyncConfig,
} from 'features/virtual-backgrounds/types';
import { SelectedVirtualBackground } from 'utils/webrtc/VirtualBackground';

export const initialState: VirtualBackgroundsState = {
  initialized: false,
  status: 'idle',
  selected: {
    type: '',
    value: '',
  },
  enforce: false,
  syncConfig: undefined,
};

export const virtualBackgroundsSlice = createSlice({
  name: 'virtualBackground',
  initialState,
  reducers: {
    virtualBackgroundChangeStarted(state) {
      state.status = 'updating';
    },
    virtualBackgroundReset(state) {
      state.selected = {
        type: '',
        value: '',
      };
      state.syncConfig = undefined;
    },
    virtualBackgroundChangeFulfilled(state) {
      state.status = 'idle';
    },
    virtualBackgroundChanged: {
      reducer: (
        state,
        action: PayloadAction<SelectedVirtualBackground, string, { sync?: boolean }>
      ) => {
        state.status = 'idle';
        state.selected = action.payload;

        if (action.meta.sync) {
          state.syncConfig = action.payload;
        }
      },
      prepare: (payload: SelectedVirtualBackground, meta: { sync?: boolean } = {}) => ({
        payload,
        meta,
      }),
    },
    virtualBackgroundFailure(state) {
      state.status = 'error';
    },
    customBackgroundApplied(state, action: PayloadAction<CustomVirtualBackground>) {
      state.customBackground = action.payload;
    },
    virtualBackgroundEnforceToggled(state, action: PayloadAction<boolean>) {
      state.enforce = action.payload;
    },
    syncConfigApplied(state, action: PayloadAction<VirtualBackgroundSyncConfig>) {
      state.syncConfig = action.payload;

      if (action.payload === false) {
        state.selected = {
          type: '',
          value: '',
        };
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initVirtualBackground.pending, (state) => {
        state.status = 'initializing';
      })
      .addCase(initVirtualBackground.fulfilled, (state) => {
        state.status = 'idle';
        state.initialized = true;
      })
      .addCase(initVirtualBackground.rejected, (state, { meta }) => {
        // if skipping because of thunk filter condition, avoid state change;
        if (!meta.condition) {
          state.status = 'error';
        }
      });
  },
});

export const {
  virtualBackgroundChanged,
  virtualBackgroundChangeStarted,
  virtualBackgroundFailure,
  virtualBackgroundReset,
  virtualBackgroundChangeFulfilled,
  customBackgroundApplied,
  virtualBackgroundEnforceToggled,
  syncConfigApplied,
} = virtualBackgroundsSlice.actions;

export default virtualBackgroundsSlice.reducer;
