import {
  globalAudioMuteToggled,
  selectIsGlobalAudioMuted,
} from 'features/application/applicationSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';

export const useGlobalAudioMuteControl = () => {
  const theme = useTheme();

  const dispatch = useAppDispatch();

  const isGlobalAudioMuted = useAppSelector(selectIsGlobalAudioMuted);

  const { t } = useTranslation('room');

  const renderLabel = () => {
    if (!isGlobalAudioMuted) {
      return t('toolbar.mute_global_audio');
    }

    return t('toolbar.unmute_global_audio');
  };

  const makeColor = () => {
    if (!isGlobalAudioMuted) {
      return theme.palette.getContrastText(theme.toolbar.background);
    }

    return theme.palette.error.main;
  };

  const toggle = () => {
    dispatch(globalAudioMuteToggled());
  };

  return {
    isMuted: isGlobalAudioMuted,
    color: makeColor(),
    label: renderLabel(),
    icon: !isGlobalAudioMuted ? 'sound' : 'no-sound',
    toggle,
  };
};
