import MobileMenuItem from 'features/toolbar/Toolbar/mobile/MobileMenuItem';
import React from 'react';
import { MobileMenuItemWrapperProps } from 'features/toolbar/types';
import { useSIPControl } from 'features/toolbar/Toolbar/controls/sip/useSIPControl';

const SIPControlMobile = ({ onClick }: MobileMenuItemWrapperProps) => {
  const { icon, isFeatureAvailable, toggleConnectionModal, label } = useSIPControl();

  if (!isFeatureAvailable) {
    return null;
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    onClick?.(event);
    toggleConnectionModal();
  };

  return (
    <MobileMenuItem icon={icon} onClick={handleClick}>
      {label}
    </MobileMenuItem>
  );
};

export default SIPControlMobile;
