import {
  selectActiveVirtualBackground,
  selectVirtualBackgroundStatus,
} from 'features/virtual-backgrounds/selectors';
import { SelectedVirtualBackground } from 'utils/webrtc/VirtualBackground';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { virtualBackgroundReset } from 'features/virtual-backgrounds/virtualBackgroundsSlice';
import { usePublishingFeed } from 'hooks/usePublishingFeed';
import { virtualBackgroundChangeRequested } from 'features/virtual-backgrounds/actions';
import VideoBackgroundTab from 'features/room/settings/tabs/VideoBackgroundTab';
import { useEffect, useState } from 'react';
import { initVirtualBackground } from 'features/virtual-backgrounds/thunks/initVirtualBackground';

const VirtualBackground = () => {
  const dispatch = useAppDispatch();

  const { videoStream } = usePublishingFeed('media');

  const status = useAppSelector(selectVirtualBackgroundStatus);
  const selectedBackground = useAppSelector(selectActiveVirtualBackground);

  const [selectedBg, setSelectedBg] = useState(selectedBackground.value);

  useEffect(() => {
    dispatch(initVirtualBackground());
  }, [dispatch]);

  const resetVB = async () => {
    if (selectedBg === '') {
      return;
    }

    setSelectedBg('');

    dispatch(virtualBackgroundReset());
  };

  const changeVB = async (config: SelectedVirtualBackground) => {
    if (selectedBg === config.value) {
      return;
    }

    setSelectedBg(config.value);

    dispatch(virtualBackgroundChangeRequested(config));
  };

  return (
    <VideoBackgroundTab
      error={status === 'error'}
      loading={status === 'initializing'}
      videoStream={videoStream}
      onChange={changeVB}
      onReset={resetVB}
      selected={selectedBackground.value}
    />
  );
};

export default VirtualBackground;
