import { AddImageToWhiteboardOptions } from 'features/sdk/onAddImageToWhiteboardSaga';
import { WhiteboardCreatedOptions } from 'features/layout/features/content/types';
import { validateImageURL } from 'utils/validateAssetURL';
import { isValidBase64Image } from 'utils/validation/base64';

function isFiniteNumber(value: any) {
  return typeof value === 'number' && Number.isFinite(value);
}

export const validateAddImageToWhiteboardOptions = async (
  options: Partial<AddImageToWhiteboardOptions>
) => {
  if (options.position) {
    const isValidPosition =
      isFiniteNumber(options.position?.x) && isFiniteNumber(options.position?.y);

    return {
      isValid: isValidPosition,
      message: !isValidPosition ? 'The provided position is invalid.' : '',
    };
  }

  if (typeof options.base64 === 'string') {
    const isValidBase64: boolean = isValidBase64Image(options.base64);

    return {
      isValid: isValidBase64,
      message: !isValidBase64
        ? 'The provided Base64 string is invalid or not a valid Base64-encoded image.'
        : '',
    };
  }

  if (typeof options.url === 'string') {
    const isValidUrl: boolean = await validateImageURL(options.url);

    return {
      isValid: isValidUrl,
      message: !isValidUrl ? 'The provided URL is invalid or does not point to a valid image.' : '',
    };
  }

  return {
    isValid: false,
    message: 'No valid Base64 string or URL was provided. Please supply one to proceed.',
  };
};

export const validateCreateWhiteboardOptions = async (
  options: Partial<WhiteboardCreatedOptions>
) => {
  const isPersonalFieldNotValid = typeof options.personal !== 'boolean';
  const isFolderIdFieldNotValid =
    typeof options.folderId === 'string' && options.folderId.length === 0;

  if (isPersonalFieldNotValid && isFolderIdFieldNotValid) {
    return {
      isValid: false,
      message: 'No valid fields was provided.',
    };
  }

  if (isFolderIdFieldNotValid) {
    return {
      isValid: false,
      message: 'The provided folder id field is invalid.',
    };
  }

  if (isPersonalFieldNotValid) {
    return {
      isValid: false,
      message: 'The provided personal field is invalid.',
    };
  }

  return {
    isValid: true,
    message: '',
  };
};
