import React, { ComponentPropsWithoutRef } from 'react';
import { styled, SxProps } from '@mui/material/styles';
import { Theme } from '@mui/material';

type CodeBoxProps = ComponentPropsWithoutRef<'div'> & SxProps<Theme>;

const Root = styled('div')(({ theme }) => ({
  padding: theme.spacing(3, 3),
  backgroundColor:
    theme.palette.mode === 'dark' ? 'rgba(255,255,255, 0.16)' : theme.palette.grey[200],
  borderRadius: '4px',
}));

const CodeBox = ({ children, ...props }: CodeBoxProps) => <Root {...props}>{children}</Root>;

export default CodeBox;
