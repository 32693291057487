import { call, put, select } from 'redux-saga/effects';
import { Action } from '@reduxjs/toolkit';
import i18n from 'i18n';
import { addActionNotification } from 'features/notifications/addActionNotification';
import { signalingQuestionEdited } from 'features/qa/actions';
import { selectToolbarPanel, toolbarPanelOpened } from 'features/toolbar/toolbarSlice';
import { ToolbarPanelType } from 'features/toolbar/types';
import { FeatureFlag } from 'features/feature-set/types';
import { selectQuestionById } from 'features/qa/qaSlice';
import { Question } from 'features/qa/types';

export function* onQuestionEditedSaga(action: ReturnType<typeof signalingQuestionEdited>) {
  const { id, editedBy, question: message, localEdit } = action.payload;

  const question: Question = yield select(selectQuestionById, id);

  if (!localEdit && question.local) {
    const activePanel: ToolbarPanelType = yield select(selectToolbarPanel);

    const actionNotificationThunk: Action = yield call(
      addActionNotification,
      {
        content: i18n.t('notifications:qa.local_question_edited.content', {
          username: editedBy.name,
          question: message,
        }),
        okText: i18n.t('notifications:qa.local_question_edited.btn_ok_label'),
        okAction: toolbarPanelOpened('qa', {
          questionId: id,
        }),
        hideOkAction: activePanel === 'qa',
        initiatorId: editedBy.id,
        scope: [FeatureFlag.qa],
        data: {
          questionId: id,
        },
      },
      {
        toastId: `${id}_${editedBy.id}`,
      }
    );

    yield put(actionNotificationThunk);
  }
}
