import React, { useContext, useEffect, useState } from 'react';
import cx from 'clsx';
import { Divider } from '@mui/material';
import Menu from 'components/Menu/Menu';
import { RoomLayoutValuesContext } from 'features/layout/Context';
import { useCustomTilePinControl } from 'features/streaming/components/controls/useCustomTilePinControl';
import { StreamControlButton } from 'features/streaming/components/StreamControlButton';
import { StreamControlMenuItem } from 'features/streaming/components/StreamControlMenuItem';
import { CustomTileData } from 'features/layout/features/config/types';

export const CustomTileControls = ({
  data,
  collapse,
}: {
  data: CustomTileData;
  collapse: boolean;
}) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const { roomWidth, roomHeight } = useContext(RoomLayoutValuesContext);
  const closeMenu = () => setMenuOpen(false);

  const { show: showPin, ...pinControl } = useCustomTilePinControl(data, closeMenu);

  const controls: any[] = [];
  const Control = collapse ? StreamControlMenuItem : StreamControlButton;

  if (showPin) {
    controls.push({ key: 'pin', ...pinControl });
  }

  const haveControls = controls.length;

  useEffect(() => {
    closeMenu();
  }, [roomWidth, roomHeight]);

  const renderControls = () =>
    controls.map((config) => {
      if (config === 'divider') {
        return <Divider />;
      }
      const { key, ...rest } = config;

      return <Control key={key} {...rest} />;
    });

  const renderCollapsedMenu = () =>
    haveControls ? (
      <Menu
        id={`tile-controls-${data.name}`}
        open={menuOpen}
        onClose={closeMenu}
        placement="top-start"
        trigger={
          <StreamControlButton
            icon="settings1"
            onClick={() => setMenuOpen((open) => !open)}
            className={cx({ 'hover-child': !menuOpen })}
          />
        }
        PaperProps={{
          sx: (theme) => ({
            backgroundColor: theme.palette.grey['900'],
          }),
        }}
        sx={(theme) => ({
          color: theme.palette.common.white,
        })}
      >
        {renderControls()}
      </Menu>
    ) : null;

  return collapse ? renderCollapsedMenu() : renderControls();
};
