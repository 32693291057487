import { notification } from 'features/notifications/toast/notification';
import { logger } from 'utils/logger';
import { store } from 'store/store';
import {
  sendingStatsUpdated,
  updateConnectionQuality,
} from 'features/system-stats/systemStatsSlice';
import { ControlledPublishingHandle } from 'utils/webrtc/publishing/ControlledPublishingHandle';
import { PublishingRTCStats } from 'utils/webrtc/publishing/PublishingRTCStats';
import ConnectionQualityNotification from 'features/system-stats/ConnectionQualityNotification';
import { WEAK_CONNECTION_NOTIFICATION_ID } from 'features/notifications/constants';
import { selectConnectionMessageEnabled } from 'features/application/applicationSlice';

const READING_DURATION_SECONDS = 5;
const QUALITY_EVALUATION_TIMEOUT = READING_DURATION_SECONDS * 1000;

export enum ConnectionQualityLevel {
  bad = 2,
  poor = 3,
  good = 4,
}

export class ConnectionQuality {
  private rtcStatsTimer?: number;

  private qualityTimer?: number;

  private previousAverage = 0;

  private goodReadingsCounter = 0;

  private consecutivePoorReadings = 0;

  private improvementHistoryValues: number[] = [];

  MOSRange: number[] = [];

  qualityLevel = ConnectionQualityLevel.good;

  rtcStats?: PublishingRTCStats;

  warmupPeriodSeconds = 30;

  readingDurationSeconds = 0;

  requiredPoorReadingsWarmup = 4;

  requiredPoorReadingsNormal = 3;

  requiredGoodReadingsForDismissal = 2;

  private captureMOS = (value: number) => {
    this.MOSRange.push(value);
    if (this.MOSRange.length > 10) {
      this.MOSRange.shift();
    }
  };

  private calculateAverageQuality = (samples: number[]): number => {
    if (samples.length < 3) {
      return ConnectionQualityLevel.good;
    }

    let sum = 0;
    for (const sample of samples) {
      sum += sample;
    }

    return sum / samples.length;
  };

  private isQualityImproving = (currentAverage: number): boolean => {
    this.improvementHistoryValues.push(currentAverage);
    if (this.improvementHistoryValues.length > 3) {
      this.improvementHistoryValues.shift();
    }

    // Detect overall trend when we have enough history
    let trendImproving = false;
    if (this.improvementHistoryValues.length >= 3) {
      const values = this.improvementHistoryValues;
      const firstValue = values[0];
      const lastValue = values[values.length - 1];
      // If the most recent reading is higher than
      // the oldest reading (within our window), we consider the trend improving
      trendImproving = lastValue > firstValue;
    }

    const isLatestBetter = currentAverage > this.previousAverage;

    // Consider improving if latest reading is better than the previous OR
    // if the overall trend over last 3 readings is upward
    return isLatestBetter || trendImproving;
  };

  private showWarningNotification = () => {
    notification(ConnectionQualityNotification, {
      position: 'top-center',
      closeOnClick: false,
      autoClose: false,
      toastId: WEAK_CONNECTION_NOTIFICATION_ID,
      className: 'weak-connection-toast',
    });

    logger
      .remote({ tier: 1 })
      .warn(
        `Connection is weak. MOS: ${this.qualityLevel.toFixed(2)}, Consecutive poor readings: ${this.consecutivePoorReadings}`
      );
  };

  private dismissWarningNotification = () => {
    if (notification.isActive(WEAK_CONNECTION_NOTIFICATION_ID)) {
      notification.dismiss(WEAK_CONNECTION_NOTIFICATION_ID);

      logger
        .remote({ tier: 1 })
        .info(`Connection quality recovered. MOS: ${this.qualityLevel.toFixed(2)}`);
    }
  };

  private handlePoorQuality = (isImproving: boolean, requiredReadings: number) => {
    this.goodReadingsCounter = 0;
    this.consecutivePoorReadings += 1;

    const sufficientPoorReadings = this.consecutivePoorReadings >= requiredReadings;
    const shouldShowWarning = sufficientPoorReadings && !isImproving;

    if (shouldShowWarning) {
      this.showWarningNotification();
    }
  };

  private handleGoodQuality = () => {
    this.consecutivePoorReadings = 0;
    this.goodReadingsCounter += 1;

    if (this.goodReadingsCounter >= this.requiredGoodReadingsForDismissal) {
      this.dismissWarningNotification();
    }
  };

  private calculateConnectionQuality = () => {
    this.qualityTimer = window.setInterval(() => {
      this.readingDurationSeconds += READING_DURATION_SECONDS;

      const MOSRange = [...this.MOSRange];
      if (MOSRange.length < 3) {
        return;
      }

      this.qualityLevel = this.calculateAverageQuality(MOSRange);
      store.dispatch(updateConnectionQuality(this.qualityLevel));

      const inWarmupPeriod = this.readingDurationSeconds < this.warmupPeriodSeconds;
      const isImproving = this.isQualityImproving(this.qualityLevel);

      this.previousAverage = this.qualityLevel;

      const requiredReadings = inWarmupPeriod
        ? this.requiredPoorReadingsWarmup
        : this.requiredPoorReadingsNormal;

      if (this.qualityLevel < ConnectionQualityLevel.poor) {
        this.handlePoorQuality(isImproving, requiredReadings);
      } else {
        this.handleGoodQuality();
      }
    }, QUALITY_EVALUATION_TIMEOUT);
  };

  initialize = (feed: ControlledPublishingHandle) => {
    this.rtcStats = new PublishingRTCStats({ feed });
    this.readingDurationSeconds = 0;
    this.consecutivePoorReadings = 0;
    this.previousAverage = 0;
  };

  captureStats = () => {
    const { rtcStats, rtcStatsTimer } = this;
    if (rtcStatsTimer) {
      return;
    }

    if (!rtcStats) {
      return;
    }

    this.rtcStatsTimer = window.setInterval(async () => {
      const stats = await rtcStats.getStats();
      if (stats) {
        // skip the first tick
        if (stats.MOS > 0) {
          this.captureMOS(stats.MOS);
        }

        store.dispatch(sendingStatsUpdated(stats));
      }
    }, 1000);

    const connectionMessageEnabled = selectConnectionMessageEnabled(store.getState());
    if (connectionMessageEnabled) {
      this.calculateConnectionQuality();
    }
  };

  stopCapturingStats = () => {
    if (this.rtcStatsTimer) {
      window.clearInterval(this.rtcStatsTimer);
      window.clearInterval(this.qualityTimer);
      this.rtcStatsTimer = undefined;
      this.qualityTimer = undefined;
    }
  };

  cleanup = () => {
    this.stopCapturingStats();
    this.rtcStats = undefined;
  };
}
