import { usePermissions } from 'features/permissions/usePermissions';
import { PermissionTypes } from 'features/permissions/types';

export const useSharedNotes = () => {
  const { hasPermissions } = usePermissions();
  // @TODO update feature flag
  // const isFeatureEnabled = useFeatureFlag('sharedNotes');
  const isFeatureEnabled = true;

  const canEditNotes = hasPermissions(PermissionTypes.collaborateInNotes);
  const canSeeNotes = hasPermissions(PermissionTypes.seeNotes);

  const isFeatureAvailable = isFeatureEnabled && canSeeNotes;

  return {
    isFeatureAvailable,
    canSeeNotes,
    canEditNotes,
  };
};
