import WaitingScreen from 'components/WaitingScreen/WaitingScreen';
import { useAppSelector } from 'store/hooks';
import { selectEndSessionData, selectWaitingScreen } from 'features/application/applicationSlice';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@mui/material';
import { selectRejoinEnabled, selectRoomStatus } from 'features/room/roomSlice';
import { Link as RouterLink } from 'react-router-dom';
import { usePageReload } from 'hooks/usePageReload';

const SessionEnded = () => {
  const { t } = useTranslation('waiting-screens');

  const { createReloadUrl } = usePageReload();

  const screen = useAppSelector(selectWaitingScreen);
  const roomStatus = useAppSelector(selectRoomStatus);
  const endSession = useAppSelector(selectEndSessionData);

  const rejoinEnabled = useAppSelector(selectRejoinEnabled);

  const renderContent = () => {
    if (roomStatus === 'ended') {
      if (endSession.endedByMe) {
        return t('end_session.local_user_ended');
      }

      if (endSession.initiatorName) {
        return t('end_session.ended', { username: endSession.initiatorName });
      }

      return t('end_session.ended_unnamed');
    }

    if (roomStatus === 'left') {
      return t('end_session.session_left');
    }

    return null;
  };

  const renderRejoinButton = () => {
    if (screen === 'sessionEnded') {
      return null;
    }

    if (!rejoinEnabled) {
      return null;
    }

    const to = createReloadUrl();

    return (
      <div>
        <Button
          type="button"
          variant="outlined"
          component={RouterLink}
          to={to}
          reloadDocument
          replace
        >
          {t('end_session.btn_rejoin')}
        </Button>
      </div>
    );
  };

  return (
    <WaitingScreen show>
      <Typography mb={2}>{renderContent()}</Typography>
      {renderRejoinButton()}
    </WaitingScreen>
  );
};

export default SessionEnded;
