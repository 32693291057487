import { useTranslation } from 'react-i18next';
import MobileMenuItem from 'features/toolbar/Toolbar/mobile/MobileMenuItem';
import { MobileMenuItemWrapperProps } from 'features/toolbar/types';
import { useSharedNotesControl } from 'features/toolbar/Toolbar/shared-notes/useSharedNotesControl';

const SharedNotesControlMobile = ({ onClick }: MobileMenuItemWrapperProps) => {
  const { t } = useTranslation('room');

  const { active, togglePanel, icon, isFeatureAvailable } = useSharedNotesControl();

  if (!isFeatureAvailable) {
    return null;
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    onClick?.(event);
    togglePanel();
  };

  return (
    <MobileMenuItem icon={icon} selected={active} onClick={handleClick}>
      {t('toolbar.shared_notes')}
    </MobileMenuItem>
  );
};

export default SharedNotesControlMobile;
