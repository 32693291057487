import React from 'react';
import { usePiPWindow } from 'components/PiP/PiPProvider';
import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CustomTileData } from 'features/layout/features/config/types';
import { CustomTileControls } from 'features/streaming/components/CustomTileControls';

const Root = styled('div')({
  position: 'absolute',
  zIndex: 10,
  bottom: '8px',
  left: '8px',
  display: 'flex',
});

interface CustomTileControlButtonsProps {
  data: CustomTileData;
  collapseButtons?: boolean;
}

export const CustomTileControlButtons = React.memo(
  ({ data, collapseButtons }: CustomTileControlButtonsProps) => {
    const { pipWindow } = usePiPWindow();

    if (pipWindow) {
      return null;
    }

    return (
      <Root className="hover-child">
        <Stack direction="row" spacing={2}>
          <CustomTileControls data={data} collapse={!!collapseButtons} />
        </Stack>
      </Root>
    );
  }
);
