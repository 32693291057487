import { call, put, select } from 'redux-saga/effects';
import { SignalingSocket } from 'services/signaling';
import {
  editAnswerClosed,
  selectAnswerById,
  selectEditedAnswer,
  selectEditingAnswerId,
} from 'features/qa/qaSlice';
import { Answer } from 'features/qa/types';

export function* editAnswerSaga() {
  const answerId: string = yield select(selectEditingAnswerId);
  const editedAnswer: string = yield select(selectEditedAnswer);
  const answer: Answer = yield select(selectAnswerById, answerId);

  yield call(SignalingSocket.send, {
    event: 'editAnswer',
    data: {
      questionId: answer.questionId,
      answer: editedAnswer,
      id: answerId,
    },
  });

  yield put(editAnswerClosed());
}
