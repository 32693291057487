import { createAction } from '@reduxjs/toolkit';
import {
  ChatMessage,
  ChatMessageDeletedPayload,
  PublicChatClearedPayload,
} from 'features/chat/types';

export const clearPublicChatRequested = createAction('chat/clearPublicChatRequested');
export const signalingPublicChatCleared = createAction<PublicChatClearedPayload>(
  'signaling/publicChatCleared'
);

export const deleteChatMessage = createAction<string>('chat/deleteMessage');

export const signalingChatMessageSent = createAction<ChatMessage>('signaling/chatMessageSent');
export const signalingChatMessageDeleted = createAction<ChatMessageDeletedPayload>(
  'signaling/chatMessageDeleted'
);

export const signalingAllChatMessagesReceived = createAction<{ messages: ChatMessage[] }>(
  'signaling/allChatMessagesReceived'
);
