import i18n from 'i18n';
import { Action } from '@reduxjs/toolkit';
import { call, put, select } from 'redux-saga/effects';
import { signalingPublicChatCleared } from 'features/chat/actions';
import { UserId } from 'features/users/types';
import { selectLocalUserId } from 'features/users/usersSlice';
import { displaySimpleNotification } from 'features/notifications/simple-notifications/displaySimpleNotification';

export function* onPublicChatClearedSaga(action: ReturnType<typeof signalingPublicChatCleared>) {
  const { user } = action.payload;

  const localUserId: UserId = yield select(selectLocalUserId);

  if (localUserId !== user.id) {
    const notificationThunk: Action = yield call(displaySimpleNotification, {
      content: i18n.t('notifications:chat.public_chat_cleared', {
        username: user.name,
      }),
    });
    yield put(notificationThunk);
  }
}
