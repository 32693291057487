import {
  exposedVideoFitmentChanged,
  selectContentRatio,
  selectContentWidth,
} from 'features/layout/features/contentDimensions/contentDimensionsSlice';
import { Fitment } from 'features/layout/features/contentDimensions/types';
import { selectPageStreams } from 'features/layout/features/pagination/paginationSlice';
import { matchAvatarSize } from 'features/layout/fitToRenderArea';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import StreamDisplay from 'features/streaming/components/stream-display/StreamDisplay';
import Tile from 'features/layout/tiles/Tile';
import { RoomLayoutValuesContext } from 'features/layout/Context';
import { SourceDetails } from 'features/layout/types';
import CustomTileDisplay from 'features/layout/tiles/CustomTileDisplay';
import { selectCustomTileById } from 'features/layout/features/config/configSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

type ContentTileProps = {
  source: SourceDetails;
};

const ContentTile = ({ source }: ContentTileProps) => {
  const { contentHeight, roomWidth, roomHeight, verticalLayout } =
    useContext(RoomLayoutValuesContext);

  const defalultContentWidthPercent = useAppSelector(selectContentWidth);

  const dispatch = useAppDispatch();
  const [ratio, setRatio] = useState(1);
  const [avatarSize, setAvatarSize] = useState(matchAvatarSize(contentHeight));

  const contentRatio = useAppSelector((state) => selectContentRatio(state, source));

  const fitment = useRef<Fitment>('crop');

  const streams = useAppSelector(selectPageStreams);

  const contentWidthPercent = useMemo(() => {
    if (verticalLayout) {
      return 1;
    }

    return streams?.length ? defalultContentWidthPercent : 1;
  }, [verticalLayout, defalultContentWidthPercent, streams]);

  const contentWidth = contentWidthPercent * roomWidth;

  useEffect(() => {
    const availableHeight = verticalLayout ? Math.min(roomHeight, contentHeight) : roomHeight;

    const roomRatio = availableHeight / roomWidth;
    const contentAreaRatio = availableHeight / contentWidth;

    // vertical layout, use predefined content size;
    if (verticalLayout) {
      setRatio(roomRatio);

      return;
    }

    if (source.kind === 'local' || source.kind === 'screenshare-local') {
      setRatio(contentAreaRatio);
    } else {
      // if content fits, use its proportions to size tile, use all available space otherwise;
      setRatio(Math.min(contentAreaRatio, contentRatio));
    }
  }, [source, contentHeight, roomWidth, roomHeight, contentRatio, verticalLayout, contentWidth]);

  useEffect(() => {
    setAvatarSize(matchAvatarSize(contentWidth * ratio));
  }, [ratio, contentWidth]);

  useEffect(() => {
    const videoFitment: Fitment = source.kind === 'screenshare-remote' ? 'fit' : 'crop';

    if (videoFitment !== fitment.current) {
      fitment.current = videoFitment;
      dispatch(exposedVideoFitmentChanged(videoFitment));
    }
  }, [fitment, ratio, contentRatio, source.kind, dispatch]);

  const customTileData = useAppSelector((state) => selectCustomTileById(state, source.userId));

  if (source.kind === 'custom' && customTileData) {
    return (
      <Tile ratio={ratio} cols={1} className="hover-parent-movement-on">
        <CustomTileDisplay data={customTileData} />
      </Tile>
    );
  }

  return (
    <Tile ratio={ratio} cols={1} className="hover-parent-movement-on">
      <StreamDisplay source={source} avatarSize={avatarSize} maximized />
    </Tile>
  );
};

export default ContentTile;
