import { createAction } from '@reduxjs/toolkit';

export const connectToPhoneBridgeRequested = createAction('sip/connectToPhoneBridgeRequested');

export const signalingPhoneBridgeConnecting = createAction('signaling/phoneBridgeConnecting');

export const signalingPhoneBridgeConnected = createAction('signaling/phoneBridgeConnected');

export const disconnectFromPhoneBridgeRequested = createAction(
  'sip/disconnectFromPhoneBridgeRequested'
);
export const signalingPhoneBridgeDisconnected = createAction('signaling/phoneBridgeDisconnected');
