import { put, select } from 'redux-saga/effects';
import { customTileAdded, customTileRemoved } from 'features/layout/features/config/configSlice';
import { CustomTileData } from 'features/layout/features/config/types';
import { selectMaximizedStream } from 'features/layout/features/content/contentSlice';
import { streamMinimized } from 'features/layout/actions';
import { SourceDetails } from 'features/layout/types';
import { CustomTileTypes } from 'utils/eventBus';

export function* onProcessCustomTileSaga(type: CustomTileTypes, data: CustomTileData) {
  if (type === 'addCustomTile') {
    yield put(customTileAdded(data));
    return;
  }

  const maximizedStream: SourceDetails | null = yield select(selectMaximizedStream);

  if (maximizedStream?.kind === 'custom' && maximizedStream.userId === data.name) {
    yield put(streamMinimized());
  }

  yield put(customTileRemoved(data.name));
}
