import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { signalingRoomJoined } from 'features/room/actions';
import { SIPState } from 'features/sip/types';
import {
  signalingPhoneBridgeConnected,
  signalingPhoneBridgeConnecting,
  signalingPhoneBridgeDisconnected,
} from 'features/sip/actions';

export const initialState: SIPState = {
  status: 'disconnected',
};

export const sipSlice = createSlice({
  name: 'sip',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(signalingRoomJoined, (state, action) => {
        if (action.payload.telephony) {
          state.status = action.payload.telephony.status;
        }
      })
      .addCase(signalingPhoneBridgeConnecting, (state) => {
        state.status = 'connecting';
      })
      .addCase(signalingPhoneBridgeConnected, (state) => {
        state.status = 'connected';
      })
      .addCase(signalingPhoneBridgeDisconnected, (state) => {
        state.status = 'disconnected';
      });
  },
});

// export const {} = sipSlice.actions;

export const selectSIPStatus = (state: RootState) => state.sip.status;

export default sipSlice.reducer;
