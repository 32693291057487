import { onControlEventListenersCommandSaga } from 'features/sdk/onControlEventListenersCommandSaga';
import { onControlTileActionSaga } from 'features/sdk/onControlTileActionSaga';
import { onControlUICallbackSaga } from 'features/sdk/onControlUICallbackSaga';
import { SDKConnected } from 'features/sdk/sdkStateSlice';
import { validateSDKInitStateSaga } from 'features/sdk/validateSDKInitStateSaga';
import { call, put, take } from 'redux-saga/effects';
import { eventBus, SDKConnectionPayload } from 'utils/eventBus';
import { mapSDKLabelsToDeviceIds } from 'utils/sdk/mapSDKLabelsToDeviceIds';
import { AudioPool } from 'utils/webrtc/AudioPool';
import { onConfigureVBCommandSaga } from 'features/sdk/onConfigureVBCommandSaga';
import { roomValidated } from 'features/room/actions';

export function* onSDKConnectedSaga(rawInitState: SDKConnectionPayload) {
  // make sure we don't process SDK connection before room validation
  yield take(roomValidated);

  const initState: SDKConnectionPayload = yield validateSDKInitStateSaga(rawInitState);

  yield call(eventBus.onConnection, initState);

  // support legacy sdk
  if (initState.mediaDevices) {
    yield call(mapSDKLabelsToDeviceIds, initState.mediaDevices);
  }

  if (initState.muteFrame) {
    yield call(AudioPool.muteAll);
  } else {
    yield call(AudioPool.unmuteAll);
  }

  if (initState.virtualBackground) {
    yield call(onConfigureVBCommandSaga, initState.virtualBackground, false);
  }

  if (initState.eventListeners?.length) {
    for (const event of initState.eventListeners) {
      yield call(onControlEventListenersCommandSaga, event.operation, {
        eventName: event.event,
        target: event.target,
      });
    }
  }

  if (initState.UICallbacks?.length) {
    for (const callback of initState.UICallbacks) {
      yield call(onControlUICallbackSaga, callback.operation, {
        name: callback.name,
      });
    }
  }

  if (initState.tileActions?.length) {
    for (const action of initState.tileActions) {
      const data = {
        name: action.name,
        properties: action.properties,
      };

      yield call(onControlTileActionSaga, action.operation, data);
    }
  }

  // Ensure this is the last line in this saga to keep the proper setup process
  yield put(SDKConnected(initState || {}));
}
