import * as Sentry from '@sentry/react';
import { selectAppReconnecting } from 'features/application/applicationSlice';
import { openModal } from 'features/modal/modalSlice';
import {
  localFeedDisconnected,
  localFeedUpdated,
  selectHDVideoSettings,
} from 'features/streaming/streamingSlice';
import { LocalFeedUpdated } from 'features/streaming/types';
import { ActiveMediaDevicePayload } from 'features/user-media/types';

import { selectAllMediaDevicePermissions } from 'features/user-media/userMediaSlice';
import { selectActiveVirtualBackground } from 'features/virtual-backgrounds/selectors';
import Janus, { JanusJS } from 'lib/janus';
import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';
import merge from 'lodash.merge';
import { store } from 'store/store';
import { noop } from 'utils/flow';
import { logger } from 'utils/logger';
import { isError } from 'utils/types';
import { ConnectionQuality } from 'utils/webrtc/ConnectionQuality';
import { defaultSimulcastMaxBitrates } from 'utils/webrtc/environment';
import { joinAsPublisherTemplate, publishTemplate, sendMessage } from 'utils/webrtc/messages';

import {
  GENERIC_JANUS_ERROR,
  JOIN_ROOM_ERROR,
  NO_PUBLISHING_FEED,
  NO_ROOM_ID,
  NO_ROOM_PIN,
} from 'utils/webrtc/errors';

import {
  FeedMediaState,
  FeedMediaStates,
  PublishingFeedStatus,
  PublishingKind,
  PublishingNegotiationMeta,
  PublishingOptions,
  PublishTemplateOptions,
  RTCClient,
} from 'utils/webrtc/index';

import { ControlledPublishingHandle } from 'utils/webrtc/publishing/ControlledPublishingHandle';
import { MediaController, MessageHandler } from 'utils/webrtc/publishing';
import { SignalingBus } from 'utils/webrtc/SignalingBus';
import { SelectedVirtualBackground, VirtualBackground } from 'utils/webrtc/VirtualBackground';
import { v4 as uuidv4 } from 'uuid';
import { SFrameManager } from 'features/e2ee/SFrameManager';
import { handleAttachError } from 'utils/webrtc/handleAttachError';
import { mediaDevicesChangeStarted } from 'features/user-media/actions';
import { stopStreamTracks } from 'features/user-media/utils';
import { getDefaultVideoConstraints } from 'features/user-media/utils/getDefaultVideoConstraints';

const negotiationMetaInitialValues: PublishingNegotiationMeta = {
  isNegotiating: false,
  pendingAudioBroadcastState: null,
  pendingAudioSignalingState: false,
  pendingHDSimulcast: null,
  pendingOffer: null,
  activeOffer: null,
  iceRestartAttempt: false,
};

export abstract class BasePublishing {
  kind: PublishingKind;

  status: PublishingFeedStatus = PublishingFeedStatus.idle;

  handle?: ControlledPublishingHandle;

  mediaController: MediaController;

  messageHandler: MessageHandler;

  signalingBus: SignalingBus;

  connectionQuality = new ConnectionQuality();

  negotiationMeta: PublishingNegotiationMeta;

  iceRestartTimer?: number;

  virtualBackground: VirtualBackground = new VirtualBackground();

  broadcastIntended: boolean = false;

  broadcasterLimit: number = 1;

  defaultMediaState: FeedMediaState = {
    enabled: false,
    allowed: false,
    broadcasting: false,
    captured: false,
  };

  mediaStates = {
    video: { ...this.defaultMediaState },
    audio: { ...this.defaultMediaState },
  };

  abstract getActiveMediaConfig: () => {
    video?: boolean | MediaTrackConstraints;
    audio?: boolean | MediaTrackConstraints;
    stream?: MediaStream;
  };

  protected constructor(kind: PublishingKind) {
    this.kind = kind;

    this.mediaController = new MediaController(this);
    this.messageHandler = new MessageHandler(this);
    this.signalingBus = new SignalingBus(this.kind);
    this.negotiationMeta = {
      ...negotiationMetaInitialValues,
    };

    const listener = async () => {
      if (this.status === PublishingFeedStatus.connected) {
        store.dispatch(mediaDevicesChangeStarted());
      }
    };

    navigator.mediaDevices.addEventListener('devicechange', listener);
  }

  private get uiState(): LocalFeedUpdated {
    const {
      status,
      mediaStates: {
        video: { enabled: videoEnabled, broadcasting: videoBroadcasting },
        audio: { enabled: audioEnabled, broadcasting: audioBroadcasting },
      },
    } = this;

    const streams = Object.values(this.mediaController.streams).map((stream) => ({
      kind: stream.kind,
      id: stream.id,
    }));

    return {
      feedId: this.handle?.feedId,
      status,
      audioEnabled,
      videoEnabled,
      audioBroadcasting,
      videoBroadcasting,
      streams,
    };
  }

  // public interface
  startVideoBroadcasting = async () => {
    if (this.mediaStates.video.broadcasting) {
      return;
    }

    this.mediaStates.video.enabled = true;
    this.mediaStates.video.broadcasting = true;

    this.updateRedux();

    const selectedVB = selectActiveVirtualBackground(store.getState());
    if (selectedVB.value) {
      logger.remote().log('Applying the virtual background');

      const streamVB = await this.activateVirtualBackground({
        type: selectedVB.type,
        value: selectedVB.value,
      });

      if (streamVB) {
        await this.requestPublish({
          stream: streamVB,
          media: {
            replaceVideo: true,
            replaceAudio: this.mediaStates.audio.enabled,
          },
        });

        return;
      }
    }

    await this.requestPublish();
  };

  stopVideoBroadcasting = async () => {
    if (!this.mediaStates.video.broadcasting) {
      return;
    }

    this.mediaStates.video.enabled = false;
    this.mediaStates.video.broadcasting = false;
    this.mediaStates.video.captured = false;

    this.updateRedux();

    await this.virtualBackground.deactivate();

    if (this.handle && this.kind === 'publishing') {
      const videoStreams = Object.values(this.handle.streams).filter(
        (stream) => stream.type === 'video'
      );

      this.signalingBus.addMessage('stop', this.handle.feedId, videoStreams);

      this.connectionQuality.stopCapturingStats();
    }

    await this.requestPublish({
      media: {
        removeVideo: true,
      },
    });
  };

  toggleVideoBroadcasting = async () => {
    logger
      .remote({ action: true })
      .info(
        `Video toggled by user, from ${
          this.mediaStates.video.broadcasting ? 'enabled' : 'disabled'
        } to ${this.mediaStates.video.broadcasting ? 'disabled' : 'enabled'}`
      );

    if (this.mediaStates.video.broadcasting) {
      await this.stopVideoBroadcasting();
    } else {
      await this.startVideoBroadcasting();
    }
  };

  startAudioBroadcasting = async () => {
    if (this.mediaStates.audio.broadcasting) {
      return;
    }

    this.mediaStates.audio.broadcasting = true;

    if (this.handle) {
      const audioStreams = Object.values(this.handle.streams).filter(
        (stream) => stream.type === 'audio'
      );
      this.signalingBus.addMessage('start', this.handle.feedId, audioStreams);
    }

    this.updateRedux();

    await this.updateAudioBroadcasting();
  };

  stopAudioBroadcasting = async (unpublish?: boolean) => {
    if (!this.mediaStates.audio.broadcasting) {
      return;
    }

    this.mediaStates.audio.broadcasting = false;

    if (this.handle && this.kind === 'publishing') {
      const audioStreams = Object.values(this.handle.streams).filter(
        (stream) => stream.type === 'audio'
      );
      this.signalingBus.addMessage('stop', this.handle.feedId, audioStreams);
    }

    if (unpublish) {
      this.mediaStates.audio.enabled = false;

      await this.requestPublish({
        media: {
          audioSend: false,
          removeAudio: true,
        },
      });
    } else {
      await this.updateAudioBroadcasting();
    }

    this.updateRedux();
  };

  toggleAudioBroadcasting = async () => {
    logger
      .remote({ action: true })
      .info(
        `Audio toggled by user, from ${
          this.mediaStates.audio.broadcasting ? 'enabled' : 'disabled'
        } to ${this.mediaStates.audio.broadcasting ? 'disabled' : 'enabled'}`
      );

    this.mediaStates.audio.broadcasting = !this.mediaStates.audio.broadcasting;

    const type = this.mediaStates.audio.broadcasting ? 'start' : 'stop';

    if (this.handle) {
      const audioStreams = Object.values(this.handle.streams).filter(
        (stream) => stream.type === 'audio'
      );

      if (audioStreams.length) {
        this.signalingBus.addMessage(type, this.handle.feedId, audioStreams);
      }
      // we've enabled audio but there's no audio stream yet.
      // remember to post an update when it appears
      else if (this.mediaStates.audio.broadcasting) {
        this.negotiationMeta.pendingAudioSignalingState = true;
      }
    }

    this.updateRedux();
    await this.updateAudioBroadcasting();
  };

  changeAudioDevice = async (device: ActiveMediaDevicePayload) => {
    await this.requestPublish({
      media: {
        replaceAudio: this.mediaStates.audio.enabled,
        audio: {
          deviceId: {
            exact: device.id,
          },
        },
      },
    });
  };

  changeVideoDeviceWithVB = async (device: ActiveMediaDevicePayload) => {
    const activeMediaConfig = this.getActiveMediaConfig();

    const originalStream = await this.mediaController.requestExternalStream({
      audio: activeMediaConfig.audio,
      video: {
        deviceId: {
          exact: device.id,
        },
      },
    });

    const sourceStream = new MediaStream();
    sourceStream.addTrack(originalStream.getVideoTracks()[0]);

    const vbStream = await this.virtualBackground.setInputMedia({
      stream: sourceStream,
    });

    const externalStream = new MediaStream();
    externalStream.addTrack(vbStream.getVideoTracks()[0]);

    const audioTrack = originalStream.getAudioTracks()[0];
    if (audioTrack) {
      externalStream.addTrack(audioTrack);
    }

    await this.requestPublish({
      stream: externalStream,
      media: {
        replaceVideo: true,
        replaceAudio: this.mediaStates.audio.enabled,
      },
    });
  };

  changeVideoDevice = async (device: ActiveMediaDevicePayload) => {
    if (this.virtualBackground.activated) {
      await this.changeVideoDeviceWithVB(device);
      return;
    }

    await this.requestPublish({
      media: {
        replaceVideo: this.mediaStates.video.enabled,
        video: {
          ...getDefaultVideoConstraints(),
          deviceId: {
            exact: device.id,
          },
        },
      },
    });
  };

  getStream = (id: string) => this.mediaController.streams[id];

  cleanupStreams = () => {
    Object.keys(this.mediaController.streams).forEach((id) => {
      this.mediaController.removeStream(id);
    });
  };

  toggleSimulcastHDLayer = async () => {
    if (!this.handle) {
      return;
    }

    if (!this.handle.videoroom.webrtcStuff.pc) {
      this.negotiationMeta.pendingHDSimulcast = !this.negotiationMeta.pendingHDSimulcast;
      return;
    }

    this.toggleHDLayer();
  };

  // internals
  // janus controls
  attachPlugin = () => {
    const connection = RTCClient.mediaServerConnector.connections.publishing;

    const videoroomHandle = uuidv4();
    if (this.status === PublishingFeedStatus.idle) {
      this.status = PublishingFeedStatus.connecting;
    }

    connection.janus.attach({
      plugin: 'janus.plugin.videoroom',
      opaqueId: connection.handle,
      success: async (videoroom) => {
        this.status = PublishingFeedStatus.connected;
        this.updateRedux();

        this.handle = { videoroom, handle: videoroomHandle, streams: {} };

        await this.joinRoom();
      },
      oncleanup: this.handleCleanup,
      error: handleAttachError(`attach_publishing_feed_${videoroomHandle}`),
      consentDialog: noop,
      webrtcState: (on) => {
        Janus.log(`Publishing Feed: WebRTC PeerConnection is ${on ? 'up' : 'down'} now`);

        if (on && this.kind === 'publishing' && this.handle) {
          this.connectionQuality.initialize(this.handle);
        }

        if (on && Janus.webRTCAdapter.browserDetails.browser === 'firefox') {
          this.handle?.videoroom.send({
            message: { request: 'configure', bitrate: this.getSimulcastMaxBitrates().medium },
          });
        }
      },
      signalingState: this.onSignalingState,
      mediaState: this.onMediaState,
      onmessage: this.messageHandler.onMessage,
      onlocaltrack: async (track, on) => {
        if (!on) {
          await this.mediaController.handleRemoveTrack(track);
          return;
        }
        this.mediaController.addStream(track);
        this.updateRedux();
      },
      iceState: this.onIceState,
      // Don't consume tracks in publishing feed
      onremotetrack: () => {},
      sframe: SFrameManager.getClient(),
    });
  };

  requestPublish = async (offerParams?: JanusJS.OfferParams) => {
    const feed = this.handle;

    if (!feed) {
      throw NO_PUBLISHING_FEED(this.kind);
    }

    const videoEnabled = this.mediaStates.video.enabled;
    const audioEnabled = this.mediaStates.audio.enabled;

    // prevent attempt to publish without devices
    if (!feed.videoroom.webrtcStuff.pc && !videoEnabled && !audioEnabled) {
      RTCClient.resetPublishOptions();

      return;
    }

    const offer = await this.prepareOffer(offerParams);

    // save upcoming request during existing negotiation
    if (this.negotiationMeta.isNegotiating) {
      logger.warn('Negotiation in progress, batching upcoming requests...');

      this.negotiationMeta.pendingOffer = offer;
      return;
    }

    this.negotiationMeta.isNegotiating = true;
    this.negotiationMeta.activeOffer = offer;

    feed.videoroom.createOffer(offer);
  };

  handleCleanup = async () => {
    this.connectionQuality.cleanup();

    await this.virtualBackground.deactivate();

    delete this.handle;

    this.cleanupStreams();

    const isReconnecting = selectAppReconnecting(store.getState());
    if (isReconnecting) {
      this.reconnectionReset();
    }

    this.updateRedux();

    logger.debug('Publishing Feed: cleaned up connection');
  };

  cleanupConnection = () => {
    logger.debug('Publishing Feed: cleaning up connection');
    const feed = this.handle;

    if (!feed || RTCClient.isMediaServerError) {
      this.handleCleanup();
    }

    if (!feed) {
      return;
    }

    if (feed.feedId) {
      RTCClient.disconnectFeed(feed.feedId);
    }

    feed.videoroom.hangup();
  };

  hangup = (stopSignalingStreams?: boolean) => {
    this.status = PublishingFeedStatus.idle;

    if (stopSignalingStreams) {
      this.stopSignalingStreams();
    }

    // TODO: We might need to move resetMediaStates to `cleanupConnection`
    this.resetMediaStates();
    this.cleanupConnection();
  };

  updateStreamingPermissions = () => {
    const mediaPermissions = selectAllMediaDevicePermissions(store.getState());

    this.mediaStates.audio.allowed = mediaPermissions.audioinput === 'granted';
    this.mediaStates.video.allowed = mediaPermissions.videoinput === 'granted';

    this.updateRedux();
  };

  configureStreamingState = (options: PublishingOptions = {}) => {
    const mediaPermissions = selectAllMediaDevicePermissions(store.getState());

    this.mediaStates.video.allowed = mediaPermissions.videoinput === 'granted';
    this.mediaStates.audio.allowed = mediaPermissions.audioinput === 'granted';

    this.mediaStates.video.enabled = this.mediaStates.video.allowed && !!options.video;
    this.mediaStates.audio.enabled = this.mediaStates.audio.allowed && !!options.audio;

    this.mediaStates.video.broadcasting = this.mediaStates.video.enabled;
    this.mediaStates.audio.broadcasting = this.mediaStates.audio.enabled;

    this.updateRedux();
  };

  publish = async (options: PublishTemplateOptions, jsep: JanusJS.JSEP) => {
    if (!this.handle) {
      return;
    }

    logger.warn(
      `Publishing ${this.kind === 'publishing' ? 'media' : 'screenshare'} as ${this.handle.feedId}`
    );

    await sendMessage(this.handle.videoroom, publishTemplate(options), jsep);
  };

  // janus events

  onSignalingState = async (state: RTCSignalingState) => {
    logger.remote({ system: true, capture: 'streaming' }).debug('Signaling state:', state);

    if (state === 'stable' || state === 'closed') {
      this.negotiationMeta.isNegotiating = false;

      if (this.negotiationMeta.pendingOffer) {
        const pendingOffer = cloneDeep(this.negotiationMeta.pendingOffer);
        this.negotiationMeta.pendingOffer = null;

        const negotiationRequired = !isEqual(this.negotiationMeta.activeOffer, pendingOffer);

        if (negotiationRequired) {
          await this.requestPublish(pendingOffer);
        }
      }

      this.negotiationMeta.activeOffer = null;
    } else {
      this.negotiationMeta.isNegotiating = true;
    }
  };

  onMediaState = async (medium: 'audio' | 'video', on: boolean, mid?: string) => {
    logger
      .remote()
      .debug(`Janus ${on ? 'started' : 'stopped'} receiving our ${medium} (mid=${mid})`);

    const feed = this.handle;

    if (!feed) {
      return;
    }

    feed.streams = feed.streams || {};

    if (on) {
      RTCClient.resetPublishOptions();

      if (mid) {
        if (medium === 'audio' && this.negotiationMeta.pendingAudioBroadcastState !== null) {
          this.resolvePendingAudioBroadcast(feed, mid);
        }

        feed.streams[mid] = { type: medium, mid, on: true };

        if (medium === 'audio' && this.negotiationMeta.pendingAudioSignalingState) {
          this.resolvePendingAudioSignalingState();
        }
      }

      if (!feed.connected && !feed.connecting) {
        feed.connecting = true;
      }
    } else {
      feed.streams[mid!].on = false;
    }

    const shouldSendUpdate = on ? !this.mediaStates[medium].captured : false;

    if (mid && shouldSendUpdate) {
      this.signalingBus.addMessage(on ? 'start' : 'stop', feed.feedId, [{ type: medium, mid }]);

      if (on) {
        this.mediaStates[medium].captured = true;

        if (medium === 'video') {
          if (this.negotiationMeta.pendingHDSimulcast) {
            this.resolvePendingHDSimulcast();
          }

          // @TODO start capturing stats in webrtcState callback once we start collecting audio stats
          this.connectionQuality.captureStats();
        }
      }
    }
  };

  onIceState = (state: RTCIceConnectionState) => {
    logger
      .remote({ system: true, capture: 'streaming' })
      .debug('Publishing feed: ice state:', state);

    const restore = (reason: 'disconnected' | 'failed') => {
      window.clearTimeout(this.iceRestartTimer);

      if (!this.negotiationMeta.iceRestartAttempt) {
        this.iceRestartTimer = window.setTimeout(async () => {
          logger
            .remote({ capture: 'streaming' })
            .warn(`Publishing peer connection ${reason}. Trying to recover...`);

          this.negotiationMeta.iceRestartAttempt = true;
          await this.requestPublish({ iceRestart: true });
        }, 5000);

        return;
      }

      const message = 'Publishing peer connection failed';
      logger.remote({ capture: 'streaming' }).error(message);
      Sentry.captureException(new Error(message));

      store.dispatch(openModal('peerConnectionFailure'));
    };

    if (state === 'connected') {
      window.clearTimeout(this.iceRestartTimer);
      this.negotiationMeta.iceRestartAttempt = false;
    }

    if (state === 'disconnected' || state === 'failed') {
      restore(state);
    }
  };

  updateRedux = () => {
    const state = this.uiState;
    store.dispatch(localFeedUpdated({ kind: this.kind, data: state }));
  };

  resetMediaStates = (media?: 'video' | 'audio') => {
    if (media) {
      this.mediaStates[media] = { ...this.defaultMediaState };
    } else {
      this.mediaStates.video = { ...this.defaultMediaState };
      this.mediaStates.audio = { ...this.defaultMediaState };
    }
  };

  reconnectionReset = () => {
    this.mediaStates.video.captured = false;
    this.mediaStates.audio.captured = false;
    this.status = PublishingFeedStatus.idle;
    this.negotiationMeta = {
      ...negotiationMetaInitialValues,
    };

    store.dispatch(localFeedDisconnected());
  };

  blockAudioPublishing = async () => {
    logger.remote().log('Audio publishing blocked');

    this.mediaStates.audio.allowed = false;
    this.mediaStates.audio.captured = false;

    if (this.mediaStates.audio.enabled) {
      await this.stopAudioBroadcasting(true);
    } else {
      this.updateRedux();
    }
  };

  blockVideoPublishing = async () => {
    logger.remote().log('Video publishing blocked');

    this.mediaStates.video.allowed = false;
    this.mediaStates.video.captured = false;

    if (this.mediaStates.video.enabled) {
      await this.stopVideoBroadcasting();
    } else {
      this.updateRedux();
    }
  };

  stopSignalingStreams = () => {
    if (this.handle) {
      this.signalingBus.addMessage('stop', this.handle.feedId, Object.values(this.handle.streams));
    }
  };

  getMediaStates = (): FeedMediaStates => ({
    audio: this.mediaStates.audio,
    video: this.mediaStates.video,
  });

  changeVirtualBackground = async (config: SelectedVirtualBackground) => {
    if (this.virtualBackground.activated) {
      logger.remote({ action: true }).log('The user changed the virtual background');

      await this.virtualBackground.changeBackground(config);
    } else {
      const streamVB = await this.activateVirtualBackground(config);
      if (streamVB) {
        if (this.mediaStates.video.broadcasting) {
          logger.remote().log('The user applied a virtual background');

          await this.requestPublish({
            stream: streamVB,
            media: {
              replaceVideo: this.mediaStates.video.enabled,
            },
          });
        } else {
          await this.virtualBackground.deactivate();
          stopStreamTracks(streamVB);
        }
      }
    }
  };

  // janus events

  resetVirtualBackground = async () => {
    logger.remote({ action: true }).log('The user disabled the virtual background');

    await this.virtualBackground.deactivate();

    if (!this.mediaStates.video.broadcasting) {
      return;
    }

    await this.requestPublish({
      media: {
        replaceVideo: true,
      },
    });
  };

  stopBroadcasting = () => {
    this.stopVideoBroadcasting();
    this.stopAudioBroadcasting();
  };

  activateVirtualBackground = async (
    config: SelectedVirtualBackground,
    params: {
      stream?: MediaStream;
    } = {}
  ) => {
    const externalStream = params.stream || (await this.mediaController.requestExternalStream());

    const sourceStream = new MediaStream();
    sourceStream.addTrack(externalStream.getVideoTracks()[0]);

    const vbStream = await this.virtualBackground.activate({
      type: config.type,
      stream: sourceStream,
      value: config.value,
    });

    if (!vbStream) {
      stopStreamTracks(sourceStream);
      stopStreamTracks(externalStream);

      return undefined;
    }

    const resultedStream = new MediaStream();
    resultedStream.addTrack(vbStream.getVideoTracks()[0]);

    const audioTrack = externalStream.getAudioTracks()[0];
    if (audioTrack) {
      resultedStream.addTrack(audioTrack);
    }

    return resultedStream;
  };

  private updateAudioBroadcasting = async () => {
    const { enabled: audioEnabled, allowed: audioAllowed } = this.mediaStates.audio;

    if (audioAllowed && !audioEnabled) {
      this.mediaStates.audio.enabled = true;

      this.updateRedux();

      await this.requestPublish({ media: { audioSend: true } });
    } else if (this.handle) {
      // don't re-negotiate anymore to keep instant feedback and better performance
      const mid = Object.values(this.handle.streams).find((stream) => stream.type === 'audio')?.mid;
      if (mid) {
        const muted =
          this.handle.videoroom.isAudioMuted(mid) && this.mediaStates.audio.broadcasting;

        if (muted) {
          this.handle.videoroom.unmuteAudio(mid);
        } else {
          this.handle.videoroom.muteAudio(mid);
        }
      } else {
        // no mid yet, awaiting media state update
        this.negotiationMeta.pendingAudioBroadcastState = this.mediaStates.audio.broadcasting;
      }
    }
  };

  private joinRoom = async () => {
    const { roomId, roomPin } = RTCClient;
    const feed = this.handle;

    if (!roomId) {
      throw NO_ROOM_ID();
    }
    if (!roomPin) {
      throw NO_ROOM_PIN();
    }

    if (!feed) {
      return;
    }

    try {
      await sendMessage(feed.videoroom, joinAsPublisherTemplate(roomId, roomPin));
    } catch (error) {
      if (isError(error)) {
        Sentry.captureException(error);
        throw JOIN_ROOM_ERROR(error.message);
      } else {
        throw error;
      }
    }
  };

  private shouldRestoreVirtualBackground = (offerParams: JanusJS.OfferParams) => {
    const selectedVB = selectActiveVirtualBackground(store.getState());

    return !offerParams.stream && !this.virtualBackground.activated && selectedVB.value;
  };

  private restoreVirtualBackground = async () => {
    const selectedVB = selectActiveVirtualBackground(store.getState());

    return this.activateVirtualBackground({
      type: selectedVB.type,
      value: selectedVB.value,
    });
  };

  private getSendEncodings = () => {
    const hdVideoSettings = selectHDVideoSettings(store.getState());
    const bitrates = this.getSimulcastMaxBitrates();
    const encodings = {
      h: { rid: 'h', active: hdVideoSettings.enabled, maxBitrate: bitrates.high },
      m: {
        rid: 'm',
        active: true,
        maxBitrate: bitrates.medium,
        scaleResolutionDownBy: 2,
      },
      l: {
        rid: 'l',
        active: true,
        maxBitrate: bitrates.low,
        scaleResolutionDownBy: 4,
      },
    };

    return Object.values(encodings);
  };

  private getSimulcastMaxBitrates = () => {
    const hdVideoSettings = selectHDVideoSettings(store.getState());

    return {
      ...defaultSimulcastMaxBitrates,
      high: hdVideoSettings.bitrate,
    };
  };

  private prepareOffer = async (offerParams?: JanusJS.OfferParams) => {
    const activeMediaConfig = this.getActiveMediaConfig();

    const videoEnabled = this.mediaStates.video.enabled;
    const audioEnabled = this.mediaStates.audio.enabled;

    const params: JanusJS.OfferParams = merge(
      {
        simulcast:
          Janus.webRTCAdapter.browserDetails.browser !== 'firefox' && this.kind === 'publishing',
        simulcastMaxBitrates: this.getSimulcastMaxBitrates(),
        sendEncodings: this.getSendEncodings(),
        media: {
          audioRecv: false,
          videoRecv: false,
          audioSend: audioEnabled,
          videoSend: videoEnabled,
          audio: audioEnabled ? activeMediaConfig.audio : undefined,
          video: videoEnabled ? activeMediaConfig.video : undefined,
          captureDesktopAudio:
            Janus.webRTCAdapter.browserDetails.browser === 'chrome' && this.kind === 'screensharing'
              ? {
                  echoCancellation: false,
                  noiseSuppression: false,
                  autoGainControl: false,
                  googAutoGainControl: false,
                }
              : undefined,
        },
        customizeSdp: (jsep: JanusJS.JSEP) => {
          // munge the SDP to enable DTX mode
          jsep.sdp = jsep.sdp.replace('useinbandfec=1', 'useinbandfec=1;usedtx=1');
        },
        success: (jsep: JanusJS.JSEP) =>
          this.publish(
            {
              audio: offerParams?.media?.audioSend ?? audioEnabled,
              video: offerParams?.media?.videoSend ?? videoEnabled,
            },
            jsep
          ),
        error: GENERIC_JANUS_ERROR(`publish_local_feed`),
      },
      offerParams
    );

    const offer = cloneDeep(params);

    // use the stream from join screen for the first publishing
    if (this.kind === 'publishing' && !this.handle?.videoroom.webrtcStuff.pc) {
      if (RTCClient.publishOptions.stream) {
        offer.stream = RTCClient.publishOptions.stream;
      }
    }

    if (videoEnabled) {
      if (this.kind === 'publishing') {
        // check whether we need to restore the virtual background stream
        // since we lose access to the previous stream on reconnect
        if (this.shouldRestoreVirtualBackground(offer)) {
          offer.stream = await this.restoreVirtualBackground();
        }
      }

      if (this.kind === 'screensharing') {
        offer.stream = activeMediaConfig.stream;
      }
    }

    return offer;
  };

  private resolvePendingAudioSignalingState = () => {
    this.negotiationMeta.pendingAudioSignalingState = false;

    if (!this.handle) {
      return;
    }

    const audioStreams = Object.values(this.handle.streams).filter(
      (stream) => stream.type === 'audio'
    );

    if (audioStreams.length) {
      this.signalingBus.addMessage('start', this.handle.feedId, audioStreams);
    }
  };

  private resolvePendingAudioBroadcast = (feed: ControlledPublishingHandle, mid: string) => {
    if (this.negotiationMeta.pendingAudioBroadcastState) {
      feed.videoroom.unmuteAudio(mid);
    } else {
      feed.videoroom.muteAudio(mid);
    }

    this.negotiationMeta.pendingAudioBroadcastState = null;
  };

  private resolvePendingHDSimulcast = () => {
    this.toggleHDLayer();

    this.negotiationMeta.pendingHDSimulcast = null;
  };

  private toggleHDLayer = () => {
    if (!this.handle) {
      return;
    }

    const videoSender = this.handle.videoroom.webrtcStuff.pc
      .getSenders()
      .find((sender) => sender.track?.kind === 'video');

    if (videoSender) {
      const parameters = videoSender.getParameters();
      parameters.encodings[0].active = !parameters.encodings[0].active;

      logger
        .remote({ action: true })
        .log(
          `HD video toggled from ${parameters.encodings[0].active ? 'disabled' : 'enabled'} to ${
            parameters.encodings[0].active ? 'enabled' : 'disabled'
          }`
        );
      videoSender.setParameters(parameters);
    }
  };
}
