import { getState } from 'store/store';
import { selectAudioSettings } from 'features/user-media/userMediaSlice';

export const getDefaultAudioConstraints = (): MediaTrackConstraints => {
  const audioSettings = selectAudioSettings(getState());

  return {
    autoGainControl: audioSettings.autogain,
    echoCancellation: audioSettings.echoCancellation,
    noiseSuppression: audioSettings.noiseSuppression,
  };
};
