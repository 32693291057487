import { call, put, select } from 'redux-saga/effects';
import { SignalingSocket } from 'services/signaling';
import {
  selectEditedQuestion,
  selectEditingQuestionId,
  editQuestionClosed,
} from 'features/qa/qaSlice';

export function* editQuestionSaga() {
  const questionId: string = yield select(selectEditingQuestionId);
  const question: string = yield select(selectEditedQuestion);

  yield call(SignalingSocket.send, {
    event: 'editQuestion',
    data: {
      question,
      id: questionId,
    },
  });

  yield put(editQuestionClosed());
}
