import { Answer, AnswerPayload } from 'features/qa/types';

export const normalizeAnswer = ({ question, ...rest }: AnswerPayload): Answer => {
  const normalizedPayload = {
    ...rest,
    questionId: question.id,
  };

  return normalizedPayload;
};
