import { takeLatest } from 'redux-saga/effects';
import {
  connectToPhoneBridgeRequested,
  disconnectFromPhoneBridgeRequested,
} from 'features/sip/actions';
import { connectToPhoneBridgeSaga } from 'features/sip/sagas/connectToPhoneBridgeSaga';
import { disconnectFromPhoneBridgeSaga } from 'features/sip/sagas/disconnectFromPhoneBridgeSaga';

export default function* sipWatcher() {
  yield takeLatest(connectToPhoneBridgeRequested, connectToPhoneBridgeSaga);
  yield takeLatest(disconnectFromPhoneBridgeRequested, disconnectFromPhoneBridgeSaga);
}
