import { call, put } from 'redux-saga/effects';
import { Action } from '@reduxjs/toolkit';
import { signalingQuestionLiveAnswerStopped } from 'features/qa/actions';
import { closeActionNotification } from 'features/notifications/thunks/closeActionNotification';

export function* onLiveAnswerStoppedSaga(
  action: ReturnType<typeof signalingQuestionLiveAnswerStopped>
) {
  const cleanupNotificationThunk: Action = yield call(
    closeActionNotification,
    `${action.payload.question.id}_${action.payload.id}`
  );
  yield put(cleanupNotificationThunk);
}
