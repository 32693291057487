import {
  backgroundsList,
  videoBackgroundsList,
} from 'features/room/settings/tabs/VideoBackgroundTab/backgrounds';
import { call } from 'redux-saga/effects';
import { eventBus, VirtualBackgroundOptions } from 'utils/eventBus';
import { validateImageURL, validateVideoURL } from 'utils/validateAssetURL';
import { canExposeInternals } from 'utils/development';

// TODO make it more modular and extendable
export function* validateVBOptionsSaga(options: Partial<VirtualBackgroundOptions>) {
  if (options.imageUrl) {
    const validUrl: boolean = yield call(validateImageURL, options.imageUrl);

    let validThumbnailUrl: boolean = false;
    if (options.thumbnailUrl) {
      validThumbnailUrl = yield call(validateImageURL, options.thumbnailUrl);
      if (!validThumbnailUrl) {
        yield call(eventBus.error, {
          name: 'virtual-bg',
          message:
            'Provided image thumbnail URL is invalid. The original image URL will be used instead. Please ensure it is publicly accessible and not blocked by CORS or a proxy',
        });
      }
    }

    if (validUrl) {
      return {
        valid: true,
        config: { type: 'image', value: options.imageUrl },
        targetOption: 'imageUrl',
        thumbnail: validThumbnailUrl ? options.thumbnailUrl : options.imageUrl,
      };
    }

    if (options.blur || options.image || options.video || options.videoUrl) {
      yield call(eventBus.error, {
        name: 'virtual-bg',
        message: 'Provided image URL is invalid. Will fall back to other options.',
      });
    } else {
      yield call(eventBus.error, {
        name: 'virtual-bg',
        message:
          'Provided image URL is invalid. Please make sure that it’s publicly available and not blocked by CORS or proxy',
      });

      return { valid: false };
    }
  }

  if (options.videoUrl) {
    const validUrl: boolean = yield call(validateVideoURL, options.videoUrl);
    let validThumbnailUrl: boolean = false;

    if (options.thumbnailUrl) {
      validThumbnailUrl = yield call(validateImageURL, options.thumbnailUrl);
    }

    if (validUrl && validThumbnailUrl) {
      return {
        valid: true,
        config: { type: 'video', value: options.videoUrl },
        targetOption: 'videoUrl',
        thumbnail: options.thumbnailUrl,
      };
    }

    if (options.blur || options.image || options.video) {
      yield call(eventBus.error, {
        name: 'virtual-bg',
        message: 'Provided video or thumbnail URL is invalid. Will fall back to other options.',
      });
    } else {
      yield call(eventBus.error, {
        name: 'virtual-bg',
        message:
          'Provided video or thumbnail URL is invalid. Please ensure it is publicly accessible and not blocked by CORS or a proxy',
      });

      return { valid: false };
    }
  }

  if (options.image) {
    if (backgroundsList[options.image]) {
      return {
        valid: true,
        config: { type: 'image', value: backgroundsList[options.image].src },
        targetOption: 'image',
      };
    }

    if (options.blur) {
      yield call(eventBus.error, {
        name: 'virtual-bg',
        message: 'Provided image key is invalid. Will fall back to other options.',
      });
    } else {
      yield call(eventBus.error, {
        name: 'virtual-bg',
        message: 'Provided image key is invalid.',
      });
      return { valid: false };
    }
  }

  // @TODO remove when ready
  if (canExposeInternals()) {
    if (options.video) {
      if (videoBackgroundsList[options.video]) {
        return {
          valid: true,
          config: { type: 'video', value: videoBackgroundsList[options.video].src },
          targetOption: 'video',
        };
      }

      if (options.blur) {
        yield call(eventBus.error, {
          name: 'virtual-bg',
          message: 'Provided video key is invalid. Will fall back to other options.',
        });
      } else {
        yield call(eventBus.error, {
          name: 'virtual-bg',
          message: 'Provided video key is invalid.',
        });
        return { valid: false };
      }
    }
  }

  if (options.blur) {
    if (options.blur === 'balanced' || options.blur === 'strong') {
      return {
        valid: true,
        config: { type: 'blur', value: options.blur },
        targetOption: 'blur',
      };
    }
    yield call(eventBus.error, {
      name: 'virtual-bg',
      message: 'Provided blur setting is invalid.',
    });

    return { valid: false };
  }

  yield call(eventBus.error, {
    name: 'virtual-bg',
    message:
      'Missing required options for virtual background. Please provide either "blur", "image" or "imageUrl" property.',
  });

  return { valid: false };
}
