import React from 'react';
import { styled } from '@mui/material/styles';
import { CustomTileData } from 'features/layout/features/config/types';
import { hoverParentStyles } from 'features/layout/useHoverParent';
import { selectShowParticipantNamesInRecording } from 'features/layout/features/config/configSlice';
import { RecorderGuard } from 'features/recorder/RecorderGuard';
import { StreamBadge } from 'features/streaming/components/stream-display/StreamBadge';
import { CustomTileControlButtons } from 'features/streaming/components/CustomTileControlButtons';
import { useAppSelector } from 'store/hooks';

type CustomTileProps = {
  data: CustomTileData;
};

const Root = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  justifyContent: 'center',
  padding: theme.spacing(4),
  borderRadius: '8px',
  overflow: 'hidden',
  '&:focus': {
    outline: 'none',
  },
  ...hoverParentStyles,
}));

const HTMLContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
}));

const CustomTileDisplay = React.memo(({ data }: CustomTileProps) => {
  const showBadge = useAppSelector(selectShowParticipantNamesInRecording);

  return (
    <Root>
      <RecorderGuard show={showBadge}>
        <StreamBadge label={data.name} />
      </RecorderGuard>
      <RecorderGuard show>
        <CustomTileControlButtons data={data} />
      </RecorderGuard>
      <HTMLContainer dangerouslySetInnerHTML={{ __html: data.html }} />
    </Root>
  );
});

export default CustomTileDisplay;
