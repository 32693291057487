import ParticipantsControl from 'features/toolbar/Toolbar/controls/participants/ParticipantsControl';
import ChatControl from 'features/toolbar/Toolbar/controls/chat/ChatControl';
import QAControl from 'features/toolbar/Toolbar/controls/qa/QAControl';
import RaiseHandControl from 'features/toolbar/Toolbar/controls/raise-hand/RaiseHandControl';
import RecordingControl from 'features/toolbar/Toolbar/controls/recording/RecordingControl';
import WhiteboardControl from 'features/toolbar/Toolbar/controls/whiteboard/WhiteboardControl';
import OverflowMenu from 'components/OverflowMenu/OverflowMenu';
import { useParticipantsControl } from 'features/toolbar/Toolbar/controls/participants/useParticipantsControl';
import { useChatControl } from 'features/toolbar/Toolbar/controls/chat/useChatControl';
import { useQAControl } from 'features/toolbar/Toolbar/controls/qa/useQAControl';
import { useRaiseHandControl } from 'features/toolbar/Toolbar/controls/raise-hand/useRaiseHandControl';
import { useRecordingControl } from 'features/toolbar/Toolbar/controls/recording/useRecordingControl';
import { useWhiteboardControl } from 'features/toolbar/Toolbar/controls/whiteboard/useWhiteboardControl';
import React from 'react';
import { usePollsControl } from 'features/toolbar/Toolbar/controls/polls/usePollsControl';
import PollsControl from 'features/toolbar/Toolbar/controls/polls/PollsControl';
import TranscriptionControl from 'features/toolbar/Toolbar/controls/transcription/TranscriptionControl';
import { useTranscriptionControl } from 'features/toolbar/Toolbar/controls/transcription/useTranscriptionControl';
import { useContentLibraryControl } from 'features/toolbar/Toolbar/controls/content-library/useContentLibraryControl';
import ContentLibraryControl from 'features/toolbar/Toolbar/controls/content-library/ContentLibraryControl';
import SharedNotesControl from 'features/toolbar/Toolbar/shared-notes/SharedNotesControl';
import { useSharedNotesControl } from 'features/toolbar/Toolbar/shared-notes/useSharedNotesControl';
import { canExposeInternals } from 'utils/development';
import { useSIPControl } from 'features/toolbar/Toolbar/controls/sip/useSIPControl';
import SIPControl from 'features/toolbar/Toolbar/controls/sip/SIPControl';

type OverflowMenuContainerProps = {
  leading: boolean;
};

const OverflowMenuContainer = React.memo(({ leading }: OverflowMenuContainerProps) => {
  const { isFeatureAvailable: participantsAvailable } = useParticipantsControl();
  const { isFeatureAvailable: chatAvailable } = useChatControl();
  const { isFeatureAvailable: qaAvailable } = useQAControl();
  const { isFeatureAvailable: contentLibraryAvailable } = useContentLibraryControl();
  const { isFeatureAvailable: raiseHandAvailable } = useRaiseHandControl();
  const { isFeatureAvailable: recordingAvailable } = useRecordingControl();
  const { isFeatureAvailable: whiteboardAvailable } = useWhiteboardControl();
  const { isFeatureAvailable: pollsAvailable } = usePollsControl();
  const { isFeatureAvailable: transcriptionAvailable } = useTranscriptionControl();
  const { isFeatureAvailable: sharedNotesAvailable } = useSharedNotesControl();
  const { isFeatureAvailable: sipAvailable } = useSIPControl();

  return (
    <OverflowMenu leading={leading}>
      {participantsAvailable && <ParticipantsControl />}
      {chatAvailable && <ChatControl />}
      {qaAvailable && <QAControl />}
      {pollsAvailable && <PollsControl />}
      {raiseHandAvailable && <RaiseHandControl />}
      {recordingAvailable && <RecordingControl />}
      {transcriptionAvailable && <TranscriptionControl />}
      {whiteboardAvailable && <WhiteboardControl />}
      {contentLibraryAvailable && <ContentLibraryControl />}
      {sharedNotesAvailable && canExposeInternals() && <SharedNotesControl />}
      {sipAvailable && canExposeInternals() && <SIPControl />}
    </OverflowMenu>
  );
});

export default OverflowMenuContainer;
