import { toolbarPanelOpened } from 'features/toolbar/toolbarSlice';
import { takeEvery, takeLatest } from 'redux-saga/effects';
import {
  clearPublicChatRequested,
  deleteChatMessage,
  signalingChatMessageSent,
  signalingPublicChatCleared,
} from 'features/chat/actions';
import { sendMessageSaga } from 'features/chat/sagas/sendMessageSaga';
import { deleteMessageSaga } from 'features/chat/sagas/deleteMessageSaga';
import { onChatMessageSentSaga } from 'features/chat/sagas/onChatMessageSentSaga';
import {
  activeTabChanged,
  exportChatRequested,
  messageSent,
  privateChatOpened,
  sendChatMessage,
} from 'features/chat/chatSlice';
import { onPrivateChatOpenedSaga } from 'features/chat/sagas/onPrivateChatOpenedSaga';
import { exportChatSaga } from 'features/chat/sagas/exportChatSaga';
import { clearPublicChatSaga } from 'features/chat/sagas/clearPublicChatSaga';
import { updateMessageCounterSaga } from './updateMessageCounterSaga';
import { onActiveTabChangedSaga } from './onActiveTabChangedSaga';
import { onChatToolbarPanelOpenedSaga } from './onChatToolbarPanelOpenedSaga';
import { onPublicChatClearedSaga } from './onPublicChatClearedSaga';

export default function* chatWatcher() {
  yield takeLatest(sendChatMessage, sendMessageSaga);
  yield takeEvery(messageSent, updateMessageCounterSaga);
  yield takeEvery(signalingChatMessageSent, onChatMessageSentSaga);

  yield takeLatest(deleteChatMessage, deleteMessageSaga);

  yield takeEvery(privateChatOpened, onPrivateChatOpenedSaga);

  yield takeEvery(activeTabChanged, onActiveTabChangedSaga);
  yield takeEvery(toolbarPanelOpened, onChatToolbarPanelOpenedSaga);

  yield takeLatest(exportChatRequested, exportChatSaga);
  yield takeLatest(clearPublicChatRequested, clearPublicChatSaga);
  yield takeLatest(signalingPublicChatCleared, onPublicChatClearedSaga);
}
