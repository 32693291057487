import { useToolbarPanel } from 'hooks/useToolbarPanel';
import { useSharedNotes } from 'features/shared-notes/useSharedNotes';

export const useSharedNotesControl = () => {
  const { toolbarPanel, togglePanel } = useToolbarPanel();

  const { isFeatureAvailable } = useSharedNotes();

  const toggleSharedNotesPanel = () => {
    togglePanel('shared-notes');
  };

  return {
    active: toolbarPanel === 'shared-notes',
    togglePanel: toggleSharedNotesPanel,
    icon: 'shared-notes',
    isFeatureAvailable,
  };
};
