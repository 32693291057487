import React, { useEffect, useId } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { closeModal } from 'features/modal/modalSlice';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from 'components/Dialog';
import { selectSIPStatus } from 'features/sip/sipSlice';
import {
  connectToPhoneBridgeRequested,
  disconnectFromPhoneBridgeRequested,
} from 'features/sip/actions';
import { SIPConnectionStatus } from 'features/sip/types';
import { useSIP } from 'features/sip/useSIP';
import Icon from 'components/Icon';

const PhoneConnectionModal = () => {
  const { t } = useTranslation('modals');
  const dispatch = useAppDispatch();

  const modalId = useId();

  const sipStatus = useAppSelector(selectSIPStatus);

  const { isFeatureAvailable } = useSIP();

  const modalShouldBeClosed = !isFeatureAvailable;

  useEffect(() => {
    if (modalShouldBeClosed) {
      dispatch(closeModal('phoneConnection'));
    }
  }, [modalShouldBeClosed, dispatch]);

  const handleCancel = () => {
    dispatch(closeModal('phoneConnection'));
  };

  const connectToBridge = () => {
    dispatch(connectToPhoneBridgeRequested());
  };

  const disconnectFromBridge = () => {
    dispatch(disconnectFromPhoneBridgeRequested());
  };

  const renderStatus = () => {
    const map: Record<SIPConnectionStatus, string> = {
      connected: t('sip.phone_connection.status.connected'),
      connecting: t('sip.phone_connection.status.connecting'),
      disconnected: t('sip.phone_connection.status.disconnected'),
    };

    return map[sipStatus];
  };

  return (
    <Dialog
      open={!modalShouldBeClosed}
      maxWidth="xs"
      onClose={handleCancel}
      aria-labelledby={`${modalId}-title`}
      aria-describedby={`${modalId}-description`}
    >
      <DialogTitle id={`${modalId}-title`}>{t('sip.phone_connection.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText id={`${modalId}-description`}>
          {t('sip.phone_connection.description')}
        </DialogContentText>
        <Box
          sx={{
            mt: 6,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <DialogContentText>
            {t('sip.phone_connection.connection_status_label')}
            <Typography
              component="span"
              sx={{
                textTransform: 'uppercase',
                fontWeight: 700,
                ml: 2,
              }}
            >
              {renderStatus()}
            </Typography>
          </DialogContentText>
          {sipStatus === 'connected' && (
            <Icon
              name="check"
              sx={{
                color: '#36DD39',
                fontSize: 28,
                ml: 1,
              }}
            />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="grayscale">
          {t('sip.phone_connection.btn_cancel_label')}
        </Button>
        {sipStatus === 'connected' ? (
          <Button onClick={disconnectFromBridge}>
            {t('sip.phone_connection.btn_disconnect_label')}
          </Button>
        ) : (
          <Button onClick={connectToBridge} disabled={sipStatus === 'connecting'}>
            {t('sip.phone_connection.btn_connect_label')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default PhoneConnectionModal;
