import { createAction } from '@reduxjs/toolkit';
import {
  AnswerEditedPayload,
  AnswerPayload,
  DeleteAnswerPayload,
  LiveAnswerActionPayload,
  Question,
  QuestionEditedPayload,
  QuestionPayload,
  UpvoteQuestionPayload,
} from 'features/qa/types';
import { EntityIdPayload, SignalingEventPayload } from 'services/signaling';

export const askQuestionRequested = createAction<string>('qa/askQuestionRequested');

export const signalingQuestionAsked = createAction<Question>('signaling/questionAsked');

export const answerQuestionRequested = createAction<{
  answer: string;
  questionId: string;
}>('qa/answerQuestionRequested');

export const signalingQuestionAnswered = createAction<AnswerPayload>('signaling/questionAnswered');

export const editQuestionRequested = createAction('qa/editQuestionRequested');

export const signalingQuestionEdited = createAction<QuestionEditedPayload>(
  'signaling/questionEdited'
);

export const editAnswerRequested = createAction('qa/editAnswerRequested');

export const signalingAnswerEdited = createAction<AnswerEditedPayload>('signaling/answerEdited');

export const upvoteQuestionRequested = createAction<EntityIdPayload>('qa/upvoteQuestionRequested');
export const signalingQuestionUpvoted = createAction<SignalingEventPayload<UpvoteQuestionPayload>>(
  'signaling/questionUpvoted'
);

export const removeUpvoteQuestionRequested = createAction<EntityIdPayload>(
  'qa/removeUpvoteQuestionRequested'
);
export const signalingQuestionUpvoteRemoved = createAction<
  SignalingEventPayload<UpvoteQuestionPayload>
>('signaling/questionUpvoteRemoved');

export const deleteQuestionRequested = createAction<string>('qa/deleteQuestionRequested');

export const signalingQuestionDeleted = createAction<EntityIdPayload>('signaling/questionDeleted');

export const deleteAnswerRequested = createAction<DeleteAnswerPayload>('qa/deleteAnswerRequested');

export const signalingAnswerDeleted = createAction<DeleteAnswerPayload>('signaling/answerDeleted');

export const signalingQuestionsReceived = createAction<{ questions: QuestionPayload[] }>(
  'signaling/questionsReceived'
);

// live answer
export const startLiveAnswerRequested = createAction<string>('qa/startLiveAnswerRequested');
export const signalingQuestionLiveAnswerStarted = createAction<AnswerPayload>(
  'signaling/questionLiveAnswerStarted'
);
export const stopLiveAnswerRequested = createAction<LiveAnswerActionPayload>(
  'qa/stopLiveAnswerRequested'
);
export const signalingQuestionLiveAnswerStopped = createAction<AnswerPayload>(
  'signaling/questionLiveAnswerStopped'
);

export const cancelLiveAnswerRequested = createAction<LiveAnswerActionPayload>(
  'qa/cancelLiveAnswerRequested'
);

export const signalingQuestionLiveAnswerCancelled = createAction<AnswerPayload>(
  'signaling/questionLiveAnswerCancelled'
);

export const questionAnswerTypeResetRequested = createAction('qa/answerTypeResetRequested');

// dismiss question
export const dismissQuestionRequested = createAction<EntityIdPayload>(
  'qa/dismissQuestionRequested'
);
export const signalingQuestionDismissed = createAction<SignalingEventPayload<EntityIdPayload>>(
  'signaling/questionDismissed'
);
export const reopenQuestionRequested = createAction<EntityIdPayload>('qa/reopenQuestionRequested');
export const signalingQuestionReopened = createAction<SignalingEventPayload<EntityIdPayload>>(
  'signaling/questionReopened'
);
