function formatAssetUrl(assetUrl: string | { src: string }, format: any = {}): string {
  const assetUrlString = typeof assetUrl === 'string' ? assetUrl : assetUrl.src;

  if (typeof format === 'function') {
    return format(assetUrlString);
  }

  const { baseUrl = '' } = format;

  if (assetUrlString.startsWith('data:')) {
    return assetUrlString;
  }
  if (assetUrlString.match(/^https?:\/\//)) {
    return assetUrlString;
  }

  return `${baseUrl.replace(/\/$/, '')}/${assetUrlString.replace(/^\.?\//, '')}`;
}

const defaultAssetUrls = {
  fonts: {
    monospace: './fonts/IBMPlexMono-Medium.woff2',
    sansSerif: './fonts/IBMPlexSans-Medium.woff2',
    serif: './fonts/IBMPlexSerif-Medium.woff2',
    draw: './fonts/Shantell_Sans-Normal-SemiBold.woff2',
  },
  icons: {
    'align-bottom-center': './icons/icon/align-bottom-center.svg',
    'align-bottom-left': './icons/icon/align-bottom-left.svg',
    'align-bottom-right': './icons/icon/align-bottom-right.svg',
    'align-bottom': './icons/icon/align-bottom.svg',
    'align-center-center': './icons/icon/align-center-center.svg',
    'align-center-horizontal': './icons/icon/align-center-horizontal.svg',
    'align-center-left': './icons/icon/align-center-left.svg',
    'align-center-right': './icons/icon/align-center-right.svg',
    'align-center-vertical': './icons/icon/align-center-vertical.svg',
    'align-left': './icons/icon/align-left.svg',
    'align-right': './icons/icon/align-right.svg',
    'align-top-center': './icons/icon/align-top-center.svg',
    'align-top-left': './icons/icon/align-top-left.svg',
    'align-top-right': './icons/icon/align-top-right.svg',
    'align-top': './icons/icon/align-top.svg',
    'arrow-left': './icons/icon/arrow-left.svg',
    'arrowhead-arrow': './icons/icon/arrowhead-arrow.svg',
    'arrowhead-bar': './icons/icon/arrowhead-bar.svg',
    'arrowhead-diamond': './icons/icon/arrowhead-diamond.svg',
    'arrowhead-dot': './icons/icon/arrowhead-dot.svg',
    'arrowhead-none': './icons/icon/arrowhead-none.svg',
    'arrowhead-square': './icons/icon/arrowhead-square.svg',
    'arrowhead-triangle-inverted': './icons/icon/arrowhead-triangle-inverted.svg',
    'arrowhead-triangle': './icons/icon/arrowhead-triangle.svg',
    'aspect-ratio': './icons/icon/aspect-ratio.svg',
    avatar: './icons/icon/avatar.svg',
    blob: './icons/icon/blob.svg',
    'bring-forward': './icons/icon/bring-forward.svg',
    'bring-to-front': './icons/icon/bring-to-front.svg',
    check: './icons/icon/check.svg',
    'checkbox-checked': './icons/icon/checkbox-checked.svg',
    'checkbox-empty': './icons/icon/checkbox-empty.svg',
    'chevron-down': './icons/icon/chevron-down.svg',
    'chevron-left': './icons/icon/chevron-left.svg',
    'chevron-right': './icons/icon/chevron-right.svg',
    'chevron-up': './icons/icon/chevron-up.svg',
    'chevrons-ne': './icons/icon/chevrons-ne.svg',
    'chevrons-sw': './icons/icon/chevrons-sw.svg',
    'clipboard-copied': './icons/icon/clipboard-copied.svg',
    'clipboard-copy': './icons/icon/clipboard-copy.svg',
    code: './icons/icon/code.svg',
    collab: './icons/icon/collab.svg',
    color: './icons/icon/color.svg',
    comment: './icons/icon/comment.svg',
    'cross-2': './icons/icon/cross-2.svg',
    cross: './icons/icon/cross.svg',
    'dash-dashed': './icons/icon/dash-dashed.svg',
    'dash-dotted': './icons/icon/dash-dotted.svg',
    'dash-draw': './icons/icon/dash-draw.svg',
    'dash-solid': './icons/icon/dash-solid.svg',
    discord: './icons/icon/discord.svg',
    'distribute-horizontal': './icons/icon/distribute-horizontal.svg',
    'distribute-vertical': './icons/icon/distribute-vertical.svg',
    dot: './icons/icon/dot.svg',
    'dots-horizontal': './icons/icon/dots-horizontal.svg',
    'dots-vertical': './icons/icon/dots-vertical.svg',
    'drag-handle-dots': './icons/icon/drag-handle-dots.svg',
    duplicate: './icons/icon/duplicate.svg',
    edit: './icons/icon/edit.svg',
    'external-link': './icons/icon/external-link.svg',
    file: './icons/icon/file.svg',
    'fill-none': './icons/icon/fill-none.svg',
    'fill-pattern': './icons/icon/fill-pattern.svg',
    'fill-semi': './icons/icon/fill-semi.svg',
    'fill-solid': './icons/icon/fill-solid.svg',
    follow: './icons/icon/follow.svg',
    following: './icons/icon/following.svg',
    'font-draw': './icons/icon/font-draw.svg',
    'font-mono': './icons/icon/font-mono.svg',
    'font-sans': './icons/icon/font-sans.svg',
    'font-serif': './icons/icon/font-serif.svg',
    'geo-arrow-down': './icons/icon/geo-arrow-down.svg',
    'geo-arrow-left': './icons/icon/geo-arrow-left.svg',
    'geo-arrow-right': './icons/icon/geo-arrow-right.svg',
    'geo-arrow-up': './icons/icon/geo-arrow-up.svg',
    'geo-check-box': './icons/icon/geo-check-box.svg',
    'geo-cloud': './icons/icon/geo-cloud.svg',
    'geo-diamond': './icons/icon/geo-diamond.svg',
    'geo-ellipse': './icons/icon/geo-ellipse.svg',
    'geo-hexagon': './icons/icon/geo-hexagon.svg',
    'geo-octagon': './icons/icon/geo-octagon.svg',
    'geo-oval': './icons/icon/geo-oval.svg',
    'geo-pentagon': './icons/icon/geo-pentagon.svg',
    'geo-rectangle': './icons/icon/geo-rectangle.svg',
    'geo-rhombus-2': './icons/icon/geo-rhombus-2.svg',
    'geo-rhombus': './icons/icon/geo-rhombus.svg',
    'geo-star': './icons/icon/geo-star.svg',
    'geo-trapezoid': './icons/icon/geo-trapezoid.svg',
    'geo-triangle': './icons/icon/geo-triangle.svg',
    'geo-x-box': './icons/icon/geo-x-box.svg',
    github: './icons/icon/github.svg',
    group: './icons/icon/group.svg',
    hidden: './icons/icon/hidden.svg',
    image: './icons/icon/image.svg',
    'info-circle': './icons/icon/info-circle.svg',
    leading: './icons/icon/leading.svg',
    link: './icons/icon/link.svg',
    'lock-small': './icons/icon/lock-small.svg',
    lock: './icons/icon/lock.svg',
    menu: './icons/icon/menu.svg',
    minus: './icons/icon/minus.svg',
    mixed: './icons/icon/mixed.svg',
    pack: './icons/icon/pack.svg',
    page: './icons/icon/page.svg',
    plus: './icons/icon/plus.svg',
    'question-mark-circle': './icons/icon/question-mark-circle.svg',
    'question-mark': './icons/icon/question-mark.svg',
    redo: './icons/icon/redo.svg',
    'reset-zoom': './icons/icon/reset-zoom.svg',
    'rotate-ccw': './icons/icon/rotate-ccw.svg',
    'rotate-cw': './icons/icon/rotate-cw.svg',
    ruler: './icons/icon/ruler.svg',
    search: './icons/icon/search.svg',
    'send-backward': './icons/icon/send-backward.svg',
    'send-to-back': './icons/icon/send-to-back.svg',
    'settings-horizontal': './icons/icon/settings-horizontal.svg',
    'settings-vertical-1': './icons/icon/settings-vertical-1.svg',
    'settings-vertical': './icons/icon/settings-vertical.svg',
    'share-1': './icons/icon/share-1.svg',
    'share-2': './icons/icon/share-2.svg',
    'size-extra-large': './icons/icon/size-extra-large.svg',
    'size-large': './icons/icon/size-large.svg',
    'size-medium': './icons/icon/size-medium.svg',
    'size-small': './icons/icon/size-small.svg',
    'spline-cubic': './icons/icon/spline-cubic.svg',
    'spline-line': './icons/icon/spline-line.svg',
    'stack-horizontal': './icons/icon/stack-horizontal.svg',
    'stack-vertical': './icons/icon/stack-vertical.svg',
    'status-offline': './icons/icon/status-offline.svg',
    'status-online': './icons/icon/status-online.svg',
    'stretch-horizontal': './icons/icon/stretch-horizontal.svg',
    'stretch-vertical': './icons/icon/stretch-vertical.svg',
    'text-align-center': './icons/icon/text-align-center.svg',
    'text-align-justify': './icons/icon/text-align-justify.svg',
    'text-align-left': './icons/icon/text-align-left.svg',
    'text-align-right': './icons/icon/text-align-right.svg',
    'tool-arrow': './icons/icon/tool-arrow.svg',
    'tool-embed': './icons/icon/tool-embed.svg',
    'tool-eraser': './icons/icon/tool-eraser.svg',
    'tool-frame': './icons/icon/tool-frame.svg',
    'tool-hand': './icons/icon/tool-hand.svg',
    'tool-highlight': './icons/icon/tool-highlight.svg',
    'tool-laser': './icons/icon/tool-laser.svg',
    'tool-line': './icons/icon/tool-line.svg',
    'tool-media': './icons/icon/tool-media.svg',
    'tool-note': './icons/icon/tool-note.svg',
    'tool-pencil': './icons/icon/tool-pencil.svg',
    'tool-pointer': './icons/icon/tool-pointer.svg',
    'tool-text': './icons/icon/tool-text.svg',
    trash: './icons/icon/trash.svg',
    'triangle-down': './icons/icon/triangle-down.svg',
    'triangle-up': './icons/icon/triangle-up.svg',
    twitter: './icons/icon/twitter.svg',
    undo: './icons/icon/undo.svg',
    ungroup: './icons/icon/ungroup.svg',
    'unlock-small': './icons/icon/unlock-small.svg',
    unlock: './icons/icon/unlock.svg',
    'vertical-align-center': './icons/icon/vertical-align-center.svg',
    'vertical-align-end': './icons/icon/vertical-align-end.svg',
    'vertical-align-start': './icons/icon/vertical-align-start.svg',
    visible: './icons/icon/visible.svg',
    'warning-triangle': './icons/icon/warning-triangle.svg',
    'zoom-in': './icons/icon/zoom-in.svg',
    'zoom-out': './icons/icon/zoom-out.svg',
  },
  translations: {
    ar: './translations/ar.json',
    ca: './translations/ca.json',
    da: './translations/da.json',
    de: './translations/de.json',
    en: './translations/en.json',
    es: './translations/es.json',
    fa: './translations/fa.json',
    fi: './translations/fi.json',
    fr: './translations/fr.json',
    gl: './translations/gl.json',
    he: './translations/he.json',
    'hi-in': './translations/hi-in.json',
    hu: './translations/hu.json',
    it: './translations/it.json',
    ja: './translations/ja.json',
    'ko-kr': './translations/ko-kr.json',
    ku: './translations/ku.json',
    languages: './translations/languages.json',
    main: './translations/main.json',
    my: './translations/my.json',
    ne: './translations/ne.json',
    no: './translations/no.json',
    pl: './translations/pl.json',
    'pt-br': './translations/pt-br.json',
    'pt-pt': './translations/pt-pt.json',
    ro: './translations/ro.json',
    ru: './translations/ru.json',
    sv: './translations/sv.json',
    te: './translations/te.json',
    th: './translations/th.json',
    tr: './translations/tr.json',
    uk: './translations/uk.json',
    vi: './translations/vi.json',
    'zh-cn': './translations/zh-cn.json',
    'zh-tw': './translations/zh-tw.json',
  },
  embedIcons: {
    codepen: './embed-icons/codepen.png',
    codesandbox: './embed-icons/codesandbox.png',
    excalidraw: './embed-icons/excalidraw.png',
    felt: './embed-icons/felt.png',
    figma: './embed-icons/figma.png',
    github_gist: './embed-icons/github_gist.png',
    google_calendar: './embed-icons/google_calendar.png',
    google_maps: './embed-icons/google_maps.png',
    google_slides: './embed-icons/google_slides.png',
    observable: './embed-icons/observable.png',
    replit: './embed-icons/replit.png',
    scratch: './embed-icons/scratch.png',
    spotify: './embed-icons/spotify.png',
    tldraw: './embed-icons/tldraw.png',
    val_town: './embed-icons/val_town.png',
    vimeo: './embed-icons/vimeo.png',
    youtube: './embed-icons/youtube.png',
  },
};

export const TLDrawLanguagesMap: Record<string, keyof typeof defaultAssetUrls.translations> = {
  'ach-UG': 'en',
  'ar-SA': 'ar',
  'cs-CZ': 'en',
  'de-DE': 'de',
  df: 'de',
  en: 'en',
  'es-ES': 'es',
  'fr-FR': 'fr',
  'it-IT': 'it',
  'nl-NL': 'en',
  'pt-PT': 'pt-pt',
  'ro-RO': 'ro',
  'ru-RU': 'ru',
  'zh-CN': 'zh-cn',
  'zh-TW': 'zh-tw',
};

type AssetUrlCollection = Record<string, Record<string, string>>;

export const getAssetUrls = (opts: any, overrides: Partial<AssetUrlCollection>) => {
  const ret: Partial<AssetUrlCollection> = {};

  for (const [assetType, assets] of Object.entries(defaultAssetUrls)) {
    ret[assetType] ??= overrides[assetType] ? { ...overrides[assetType] } : {};

    for (const [assetName, assetValue] of Object.entries(assets)) {
      if (!ret[assetType]?.[assetName]) {
        ret[assetType]![assetName] = formatAssetUrl(assetValue, opts);
      }
    }
  }

  return ret;
};
