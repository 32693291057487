import office from 'assets/images/vb/office.jpg';
import officeThumb from 'assets/images/vb/thumb_office.jpg';
import beach from 'assets/images/vb/beach.jpg';
import beachThumb from 'assets/images/vb/thumb_beach.jpg';
import bookshelf from 'assets/images/vb/bookshelf.jpg';
import bookshelfThumb from 'assets/images/vb/thumb_bookshelf.jpg';
import fireworks from 'assets/images/vb/fireworks.jpg';
import fireworksThumb from 'assets/images/vb/thumb_fireworks.jpg';
import forest from 'assets/images/vb/forest.jpg';
import forestThumb from 'assets/images/vb/thumb_forest.jpg';
import mountain from 'assets/images/vb/mountain.jpg';
import mountainThumb from 'assets/images/vb/thumb_mountain.jpg';
import office2 from 'assets/images/vb/office2.jpg';
import office2Thumb from 'assets/images/vb/thumb_office2.jpg';
import savana from 'assets/images/vb/savana.jpg';
import savanaThumb from 'assets/images/vb/thumb_savana.jpg';
import vbg1 from 'assets/images/vb/v_bg01.jpg';
import vbg2 from 'assets/images/vb/v_bg02.jpg';
import vbg3 from 'assets/images/vb/v_bg03.jpg';
import vbg4 from 'assets/images/vb/v_bg04.jpg';

export const backgroundsList: Record<string, { src: string; thumb: string; alt: string }> = {
  office: {
    src: office,
    thumb: officeThumb,
    alt: 'Office',
  },
  office2: {
    src: office2,
    thumb: office2Thumb,
    alt: 'Office 2',
  },
  beach: {
    src: beach,
    thumb: beachThumb,
    alt: 'Palm beach',
  },
  fireworks: {
    src: fireworks,
    thumb: fireworksThumb,
    alt: 'Fireworks',
  },
  bookshelf: {
    src: bookshelf,
    thumb: bookshelfThumb,
    alt: 'Bookshelf',
  },
  forest: {
    src: forest,
    thumb: forestThumb,
    alt: 'Forest',
  },
  mountain: {
    src: mountain,
    thumb: mountainThumb,
    alt: 'Mountain',
  },
  savannah: {
    src: savana,
    thumb: savanaThumb,
    alt: 'Savana',
  },
};

export const videoBackgroundsList: Record<string, { src: string; thumb: string; alt: string }> = {
  vbg1: {
    src: 'https://res.cloudinary.com/dvgipbnfm/video/upload/v1742312047/Motion_Graphic_1920x1080_by8r3t.mp4',
    thumb: vbg1,
    alt: 'vbg1',
  },
  vbg2: {
    src: 'https://res.cloudinary.com/dvgipbnfm/video/upload/v1742312046/Motion_Graphic_tech_1280x720_mdyn4w.mp4',
    thumb: vbg2,
    alt: 'vbg2',
  },
  vbg3: {
    src: 'https://res.cloudinary.com/dvgipbnfm/video/upload/v1742312046/Motion_Graphic1_1280x720_nw7uwz.mp4',
    thumb: vbg3,
    alt: 'vbg3',
  },
  vbg4: {
    src: 'https://res.cloudinary.com/dvgipbnfm/video/upload/v1741952909/video_bg_autumn_c20peb.mp4',
    thumb: vbg4,
    alt: 'vbg4',
  },
};

export const backgrounds = Object.values(backgroundsList);

export const videoBackgrounds = Object.values(videoBackgroundsList);
