import { call, put, select } from 'redux-saga/effects';
import { Action } from '@reduxjs/toolkit';
import i18n from 'i18n';
import { addActionNotification } from 'features/notifications/addActionNotification';
import { signalingQuestionLiveAnswerStarted } from 'features/qa/actions';
import { Question } from 'features/qa/types';
import { selectQuestionById } from 'features/qa/qaSlice';
import { selectToolbarPanel, toolbarPanelOpened } from 'features/toolbar/toolbarSlice';
import { ToolbarPanelType } from 'features/toolbar/types';
import { FeatureFlag } from 'features/feature-set/types';
import { displaySimpleNotification } from 'features/notifications/simple-notifications/displaySimpleNotification';

export function* onLiveAnswerStartedSaga(
  action: ReturnType<typeof signalingQuestionLiveAnswerStarted>
) {
  const question: Question = yield select(selectQuestionById, action.payload.question.id);
  if (!question) {
    return;
  }

  if (action.payload.local) {
    const notificationThunk: Action = yield call(displaySimpleNotification, {
      content: i18n.t('notifications:qa.live_answer_started_local', {
        username: question.user.name,
      }),
    });
    yield put(notificationThunk);

    return;
  }

  const activePanel: ToolbarPanelType = yield select(selectToolbarPanel);

  const actionNotificationThunk: Action = yield call(
    addActionNotification,
    {
      content: i18n.t('notifications:qa.live_answer_started.content', {
        username: action.payload.user.name,
        question: question.question,
      }),
      okText: i18n.t('notifications:qa.live_answer_started.btn_ok_label'),
      okAction: toolbarPanelOpened('qa', {
        questionId: question.id,
      }),
      hideOkAction: activePanel === 'qa',
      initiatorId: action.payload.user.id,
      scope: [FeatureFlag.qa],
      data: {
        questionId: question.id,
      },
    },
    {
      toastId: `${question.id}_${action.payload.id}`,
    }
  );

  yield put(actionNotificationThunk);
}
