import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { authorizeUser } from 'features/room/thunks/authorizeUser';
import { RootState } from 'store/store';
import { prepareActiveDevices, updateMediaDevicesPermissions } from 'features/user-media/utils';
import {
  ActiveMediaDevicePayload,
  EnumeratedMediaDevices,
  JoinMediaDefaults,
  MediaDevicesPermissions,
  UpdateMediaDevicesMeta,
  UserMediaFailurePayload,
  UserMediaReceivedPayload,
  UserMediaState,
} from 'features/user-media/types';
import { streamingDeviceChanged } from 'features/streaming/actions';
import { normalizeActiveDevices } from 'features/user-media/utils/normalizeActiveDevices';
import { defaultHDResolution } from 'utils/webrtc/environment';

export const initialState: UserMediaState = {
  deviceAutoChange: {
    audiooutput: false,
    videoinput: false,
    audioinput: false,
  },
  activeDevices: {},
  devices: {
    entities: [],
    byKind: {
      audioinput: [],
      audiooutput: [],
      videoinput: [],
    },
  },
  permissions: {},
  joinDefaults: {
    audio: false,
    video: false,
  },
  videoFacingMode: '',
  audio: {
    quality: 32000,
    autogain: true,
    echoCancellation: false,
    noiseSuppression: false,
  },
  video: {
    resolution: defaultHDResolution,
  },
};

export const userMediaSlice = createSlice({
  name: 'userMedia',
  initialState,
  reducers: {
    mediaDevicesUpdated: {
      reducer(
        state,
        action: PayloadAction<EnumeratedMediaDevices, string, UpdateMediaDevicesMeta>
      ) {
        state.devices.byKind = action.payload;
        const entities = Object.values(action.payload).flat();

        // TODO figure out whether we need to group devices in enumerateDevices()
        state.devices.entities = entities;

        if (!Object.keys(state.activeDevices).length || action.meta.updateActiveDevices) {
          const activeDevices = prepareActiveDevices(action.payload);

          if (action.meta.activeDevices) {
            Object.assign(
              activeDevices,
              normalizeActiveDevices(entities, action.meta.activeDevices)
            );
          }

          state.activeDevices = activeDevices;
        }

        if (!Object.keys(state.permissions).length || action.meta.updatePermissions) {
          const permissions = updateMediaDevicesPermissions(
            action.payload,
            !Object.keys(state.permissions).length
          );
          Object.assign(state.permissions, permissions);
        }
      },
      prepare(payload: EnumeratedMediaDevices, meta: UpdateMediaDevicesMeta = {}) {
        return { payload, meta };
      },
    },
    mediaDevicesPermissionsUpdated(state, action: PayloadAction<MediaDevicesPermissions>) {
      Object.assign(state.permissions, action.payload);
    },
    activeMediaDeviceUpdated(state, action: PayloadAction<ActiveMediaDevicePayload>) {
      state.activeDevices[action.payload.kind] = action.payload.id;
    },
    userMediaReceived(state, action: PayloadAction<UserMediaReceivedPayload>) {
      const { device, permissions, updateActiveDevice } = action.payload;
      if (updateActiveDevice) {
        state.activeDevices[device.kind] = device.id;
      }
      Object.assign(state.permissions, permissions);
    },
    userMediaFailed(state, action: PayloadAction<UserMediaFailurePayload>) {
      Object.assign(state.permissions, { [action.payload.kind]: action.payload.value });

      if (state.activeDevices[action.payload.kind]) {
        delete state.activeDevices[action.payload.kind];
      }
    },
    facingModeChanged(state, action: PayloadAction<string>) {
      state.videoFacingMode = action.payload;
    },
    devicesAutoChanged(state, action: PayloadAction<ActiveMediaDevicePayload>) {
      state.activeDevices[action.payload.kind] = action.payload.id;
      state.deviceAutoChange[action.payload.kind] = true;
    },
    disableDeviceAutoChangeAlert(state, action: PayloadAction<MediaDeviceKind>) {
      state.deviceAutoChange[action.payload] = false;
    },
    joinDefaultsChanged(state, action: PayloadAction<JoinMediaDefaults>) {
      state.joinDefaults = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(streamingDeviceChanged, (state, action) => {
        state.activeDevices[action.payload.kind] = action.payload.id;
      })
      .addCase(authorizeUser.fulfilled, (state, action) => {
        const { media } = action.payload;
        state.joinDefaults = {
          audio: media.audio,
          video: media.video,
        };
        state.audio = {
          quality: media.audioQuality,
          autogain: media.audioAutogain,
          noiseSuppression: media.audioNoiseSuppression,
          echoCancellation: media.audioEchoCancellation,
        };
        state.video = {
          resolution: media.hdVideoQuality.resolution,
        };
      });
  },
});

export const {
  mediaDevicesUpdated,
  mediaDevicesPermissionsUpdated,
  activeMediaDeviceUpdated,
  userMediaFailed,
  userMediaReceived,
  devicesAutoChanged,
  disableDeviceAutoChangeAlert,
  facingModeChanged,
  joinDefaultsChanged,
} = userMediaSlice.actions;

export default userMediaSlice.reducer;

export const selectMediaDevices = createSelector(
  (state: RootState) => state.userMedia.devices,
  (devices) => devices.entities
);

export const selectMediaDevicesByKind = createSelector(
  (state: RootState) => state.userMedia.devices,
  (devices) => devices.byKind
);

export const selectMediaDeviceByKind = createSelector(
  [selectMediaDevicesByKind, (state, kind: MediaDeviceKind) => kind],
  (devices, kind) => devices[kind]
);

export const selectAllMediaDevicePermissions = (state: RootState) => state.userMedia.permissions;

export const selectMediaDevicePermissions = (state: RootState, kind: MediaDeviceKind) =>
  state.userMedia.permissions[kind];

export const selectActiveMediaDevices = (state: RootState) => state.userMedia.activeDevices;

export const selectActiveMediaDevice = (state: RootState, kind: MediaDeviceKind) =>
  state.userMedia.activeDevices[kind];

export const selectDeviceAutoChangeStatus = (state: RootState) => state.userMedia.deviceAutoChange;

export const selectJoinMediaDefaults = (state: RootState) => state.userMedia.joinDefaults;

export const selectVideoFacingMode = (state: RootState) => state.userMedia.videoFacingMode;

export const selectAudioSettings = (state: RootState) => state.userMedia.audio;

export const selectVideoSettings = (state: RootState) => state.userMedia.video;
