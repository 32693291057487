import VideoRenderer from 'components/VideoRenderer';
import { TabPanelInner } from 'components/Tabs';
import { Box, CircularProgress, Typography } from '@mui/material';
import { selectCustomVirtualBackground } from 'features/virtual-backgrounds/selectors';
import { useAppSelector } from 'store/hooks';
import { SelectedVirtualBackground } from 'utils/webrtc/VirtualBackground';
import BackgroundTile from 'features/room/settings/tabs/VideoBackgroundTab/BackgroundTile';
import Icon from 'components/Icon';
import {
  backgrounds,
  videoBackgrounds,
} from 'features/room/settings/tabs/VideoBackgroundTab/backgrounds';
import React, { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import webrtcAdapter from 'webrtc-adapter';
import { canExposeInternals } from 'utils/development';

export type VideoBackgroundTabProps = {
  videoStream: MediaStream | null;
  onChange: (config: SelectedVirtualBackground) => void;
  onReset: () => void;
  selected: string;
  loading?: boolean;
  error?: boolean;
};

const CenteredBox = styled('div')({
  minHeight: '250px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const VideoBackgroundTab = ({
  videoStream,
  onChange,
  onReset,
  selected,
  loading,
  error,
}: VideoBackgroundTabProps) => {
  const { t } = useTranslation(['settings', 'common']);

  const customBackground = useAppSelector(selectCustomVirtualBackground);
  const imageOptions = useMemo(() => {
    if (customBackground?.type === 'image') {
      return [
        ...backgrounds,
        {
          src: customBackground.value,
          thumb: customBackground.thumbnail,
          alt: 'Custom background',
        },
      ];
    }
    return backgrounds;
  }, [customBackground]);

  const videoOptions = useMemo(() => {
    if (customBackground?.type === 'video') {
      return [
        ...videoBackgrounds,
        {
          src: customBackground.value,
          thumb: customBackground.thumbnail,
          alt: 'Custom background',
        },
      ];
    }

    return videoBackgrounds;
  }, [customBackground]);

  if (error) {
    return (
      <TabPanelInner>
        <CenteredBox>
          <Typography>{t('common:generic_error_short')}</Typography>
        </CenteredBox>
      </TabPanelInner>
    );
  }

  if (loading) {
    return (
      <TabPanelInner>
        <CenteredBox>
          <CircularProgress disableShrink />
        </CenteredBox>
      </TabPanelInner>
    );
  }

  const isSafari = webrtcAdapter.browserDetails.browser === 'safari';

  return (
    <TabPanelInner>
      <VideoRenderer mediaStream={videoStream} sx={{ mb: 8 }} />
      <Box
        sx={{
          display: 'grid',
          gap: '8px',
          gridAutoRows: '60px',
          gridTemplateColumns: 'repeat(auto-fit, 88px);',
          justifyContent: 'center',
        }}
      >
        <BackgroundTile selected={selected === ''} onClick={onReset} bordered>
          <Icon name="virtual-bg-no-bg" aria-label={t('virtual_bg.aria_label_no_bg')} />
        </BackgroundTile>
        <BackgroundTile
          tooltipText={isSafari ? t('virtual_bg.blur_not_supported') : undefined}
          disabled={isSafari}
          selected={selected === 'balanced'}
          onClick={() => onChange({ type: 'blur', value: 'balanced' })}
          bordered
        >
          <Icon name="virtual-bg-small-blur" aria-label={t('virtual_bg.aria_label_light_blur')} />
        </BackgroundTile>
        <BackgroundTile
          disabled={isSafari}
          tooltipText={isSafari ? t('virtual_bg.blur_not_supported') : undefined}
          selected={selected === 'strong'}
          onClick={() => onChange({ type: 'blur', value: 'strong' })}
          bordered
        >
          <Icon name="virtual-bg-strong-blur" aria-label={t('virtual_bg.aria_label_strong_blur')} />
        </BackgroundTile>
        {imageOptions.map((bg, i) => (
          <BackgroundTile
            key={i}
            selected={selected === bg.src}
            onClick={() => onChange({ type: 'image', value: bg.src })}
          >
            <img src={bg.thumb} alt={bg.alt} />
          </BackgroundTile>
        ))}
        {canExposeInternals()
          ? videoOptions.map((bg, i) => (
              <BackgroundTile
                key={i}
                selected={selected === bg.src}
                onClick={() => onChange({ type: 'video', value: bg.src })}
              >
                <img src={bg.thumb} alt={bg.alt} />
              </BackgroundTile>
            ))
          : null}
      </Box>
    </TabPanelInner>
  );
};

export default VideoBackgroundTab;
