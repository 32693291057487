const canEmbedImageInCanvas = (image: HTMLImageElement) => {
  try {
    const canvas = document.createElement('canvas');
    canvas.width = image.naturalWidth;
    canvas.height = image.naturalHeight;

    const ctx = canvas.getContext('2d');
    if (!ctx) {
      return false;
    }

    ctx.drawImage(image, 0, 0);

    return true;
  } catch {
    return false;
  }
};

export const validateImageURL = async (url: string): Promise<boolean> => {
  const img = new Image();
  img.crossOrigin = 'Anonymous';
  img.src = url;

  const loaderPromise: Promise<boolean> = new Promise((resolve) => {
    img.onerror = () => resolve(false);

    img.onload = () => resolve(canEmbedImageInCanvas(img));
  });

  const timeoutPromise: Promise<boolean> = new Promise((resolve) => {
    setTimeout(() => resolve(false), 5000);
  });

  return Promise.race([loaderPromise, timeoutPromise]);
};

const canEmbedVideoInCanvas = (video: HTMLVideoElement) => {
  try {
    if (video.videoWidth <= 0 || video.videoHeight <= 0) {
      return false;
    }

    const canvas = document.createElement('canvas');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    const ctx = canvas.getContext('2d');
    if (!ctx) {
      return false;
    }

    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

    try {
      ctx.getImageData(0, 0, 1, 1);
    } catch (e) {
      return false;
    }

    return true;
  } catch {
    return false;
  }
};

export const validateVideoURL = async (url: string): Promise<boolean> => {
  const video = document.createElement('video');
  video.crossOrigin = 'Anonymous';
  video.muted = true;
  video.preload = 'metadata';

  let checkInterval: number | null = null;
  let tryWithAutoPreload = false;

  const clearTimer = () => {
    if (checkInterval) {
      clearInterval(checkInterval);
      checkInterval = null;
    }
  };

  const loaderPromise: Promise<boolean> = new Promise((resolve) => {
    video.onerror = () => {
      clearTimer();
      resolve(false);
    };

    video.src = url;
    video.load();

    let attemptStart = Date.now();

    checkInterval = window.setInterval(() => {
      if (video.readyState >= 3) {
        clearTimer();
        try {
          const isValid = canEmbedVideoInCanvas(video);
          resolve(isValid);
        } catch (err) {
          resolve(false);
        }
      } else if (!tryWithAutoPreload && Date.now() - attemptStart > 2000) {
        tryWithAutoPreload = true;
        video.preload = 'auto';
        video.load();
        attemptStart = Date.now();
      }
    }, 100);
  });

  const timeoutPromise: Promise<boolean> = new Promise((resolve) => {
    setTimeout(() => {
      clearTimer();
      resolve(false);
    }, 5000);
  });

  return Promise.race([loaderPromise, timeoutPromise]);
};
